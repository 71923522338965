import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./AgentModal.module.scss";

const AgentModal = ({ show, handleClose, callback, data, denunciante, denunciado, testigo }) => {
  const [preCarga, setPreCarga] = useState(true);
  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  return data ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Agentes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mt-2 mb-3">
              <Row className="mb-3">
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingFirstName"
                      required={!preCarga}
                      type="text"
                      value={data.first_name}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingFirstName" className={styles.label}>
                      Nombres
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingLastName"
                      required={!preCarga}
                      type="text"
                      value={data.last_name}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingLastName" className={styles.label}>
                      Apellidos
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingDocumentType"
                      as="select"
                      value={data.document_type}
                      className={styles.input}
                      readOnly
                    >
                      <option value="">Seleccione tipo de Documento</option>
                      <option value="CI">Cédula de identidad</option>
                      <option value="PAS">Pasaporte</option>
                      <option value="MAT">
                        Matrícula de la Corte Suprema de Justicia
                      </option>
                    </Form.Control>
                    <Form.Label htmlFor="floatingDocumentType" className={styles.label}>
                      Tipo de documento
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingDocumentNumber"
                      required={!preCarga}
                      type="text"
                      value={data.document_number}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingDocumentNumber" className={styles.label}>
                      Número de documento
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingDocumentNumber"
                      required={!preCarga}
                      type="text"
                      value={data.matricula_number}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingDocumentNumber" className={styles.label}>
                      Número de matrícula
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingBirthDate"
                      required={!preCarga}
                      value={data.birth_date}
                      className={styles.input}
                      readOnly
                      type="text"
                      max={new Date().toISOString().split("T")[0]}
                    />
                    <Form.Label htmlFor="floatingBirthDate" className={styles.label}>
                      Fecha de nacimiento
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>

              </Row>
              <Row>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingGender"
                      type="text"
                      value={
                        data.gender === "M"
                          ? "Masculino"
                          : data.gender === "F"
                            ? "Femenino"
                            : data.gender}
                      className={styles.input}
                      readOnly
                    >
                    </Form.Control>
                    <Form.Label htmlFor="floatingGender" className={styles.label}>
                      Genero
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingMaritalStatus"
                      type="text"
                      value={data.marital_status}
                      className={styles.input}
                      readOnly
                    >
                    </Form.Control>
                    <Form.Label htmlFor="floatingMaritalStatus" className={styles.label}>
                      Estado civil
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingProfession"
                      required={!preCarga}
                      type="text"
                      value={data.profession}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingProfession" className={styles.label}>
                      Profesión
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingAlias"
                      required={!preCarga}
                      type="text"
                      value={data.alias}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingAlias" className={styles.label}>
                      Alias
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default AgentModal;
