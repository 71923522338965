import React from "react";
import { Modal, Button, Row } from "react-bootstrap";
import styles from "./AgentModal.module.css";
import ComplainsGrid from "../../DenunciaForm/Complains";
import { GoPersonAdd } from "react-icons/go";

const AgentModal = ({ 
  show, 
  handleClose,
  setIsAddAgent,
  person,
  deleteAgents,
  setSelectedPerson,
  setMatriculaNumber,
  setSelectedMatricula, }) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Representantes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={() => {
                setIsAddAgent(true);
                handleClose();
              }}
              bsPrefix={styles.next}
            >
              Agregar Representante <GoPersonAdd />
            </Button>
          </div>
          <Row className="mb-4">
            <ComplainsGrid
              person={person}
              deleteAgents={deleteAgents}
              setSelectedPerson={setSelectedPerson}
              setMatriculaNumber={setMatriculaNumber}
              setSelectedMatricula={setSelectedMatricula}
            />
          </Row>
        </Modal.Body>
      </Modal>
  );
};

export default AgentModal;
