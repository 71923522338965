import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Table, Badge } from "react-bootstrap";
import styles from "./Steps.module.scss";

const Step1 = ({ next, data}) => {

  return data ? (
    <>
      <Form>
      <Form.Group className={styles.group}>
                    <Form.Label as={Col} className={styles.groupLabel}>Perjudicados</Form.Label>
                    {
                        data.injuredParties && data.injuredParties.length > 0 ? (
                            <>
                                {
                                    data.injuredParties.map((injuredParty) => (
                                        <Badge bg="secondary">{injuredParty}</Badge>
                                    ))
                                }
                            </>
                        )
                        :
                        <Row>
                            <Form.Label className={styles.noContent}>No hay elementos</Form.Label>
                        </Row>
                    }
                </Form.Group>                
      </Form>
    </>
  ) : (
    <></>
  );
};

export default Step1;
