import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Table, Badge } from "react-bootstrap";
import styles from "./Steps.module.scss";
import { checkRoles } from "../../../../../Keycloak";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import PersonInstitutionModal from "../../DenunciasContext/Edit/PersonInstitutionModal/PersonInstitutionModal";

const Step4 = ({
  data,
  callback,
  denunciante,
  denunciado,
  testigo, currentComplainantIndex, currentDefendantIndex, currentWitnessIndex
}) => {
  const { keycloak } = useKeycloak();
  const [person, setPerson] = useState(null);
  const [showPersonInstitution, setShowPersonInstitution] = useState(false);
  const [selectedPersonInstitution, setSelectedPersonInstitution] = useState(null);
  const [loadingInstitution, setLoadingInstitution] = useState(false);
  const [institution, setInstitution] = useState(null);
  const [institutionOptions, setInstitutionOptions] = useState([]);

  useEffect(() => {
    if (data && (denunciante || denunciado || testigo)) {
      let selectedPerson = null;

      if (denunciante) {
        selectedPerson = data.complainants[currentComplainantIndex] || null;
      } else if (denunciado) {
        selectedPerson = data.defendants[currentDefendantIndex] || null;
      } else if (testigo) {
        selectedPerson = data.witnesses[currentWitnessIndex] || null;
      }

      if (selectedPerson) {
        setPerson(selectedPerson);
      }
    }
  }, [data, denunciante, denunciado, testigo,currentComplainantIndex, currentDefendantIndex, currentWitnessIndex]);

  const prepareHeaders = () => {
    return {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  useEffect(() => {
    if (person && person.person_institutions && person.person_institutions.person_institutionId) {
      setLoadingInstitution(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/institutions/${person.person_institutions.person_institutionId}`,
          prepareHeaders()
        )
        .then((response) => {
          setInstitution(response.data);
          setLoadingInstitution(false);
        })
        .catch((error) => {
          console.error("Error fetching institution:", error);
          setLoadingInstitution(false);
        });
    }
  }, [person]);

  return (
    <>
    <PersonInstitutionModal
        data={selectedPersonInstitution}
        show={showPersonInstitution}
        handleClose={() => setShowPersonInstitution(false)}
      />
      <Form>
        {person && denunciado && (<>
          <Form.Label className={styles.groupLabel}>
              Datos Adicionales
            </Form.Label>
            <Form.Group as={Col} md={2} controlId="pepValidation">
              <Form.Check
                label="Persona Expuesta Políticamente"
                type="switch"
                name="pep"
                id="pep"
                style={{
                  accentColor: "#AD0039",
                  marginTop: "auto",
                  marginBottom: "auto",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                }}
                checked={person.pep_boolean || false}
                readOnly
                className={styles.label}
              />
            </Form.Group><br/>
            <Form.Group as={Col} md={2} controlId="pepValidation">
              <Form.Check
                label="Funcionario Público"
                type="switch"
                name="publicOfficial"
                id="publicOfficial"
                style={{
                  accentColor: "#AD0039",
                  marginTop: "auto",
                  marginBottom: "auto",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                }}
                checked={person.publicOfficial_boolean || false}
                readOnly={true}
                className={styles.label}
              />
            </Form.Group>
          </>)}
      </Form>
    </>
  );
};

export default Step4;
