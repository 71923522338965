import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClear } from "react-icons/ai";
import {
  Form,
  Button,
  Col,
  Row,
  DropdownButton,
  Dropdown,
  Badge,
  Table,
  InputGroup,
} from "react-bootstrap";
import {
  RiSortAsc,
  RiSortDesc,
  RiCloseFill,
  RiTableFill,
  RiArticleFill,
} from "react-icons/ri";
import styles from "./Recepciones.module.css";
import CustomPagination from "../../components/pagination/CustomPagination";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { checkRoles } from "../../Keycloak";
import { FaFileCirclePlus } from "react-icons/fa6";
import ReceptionProsecutorDivisions from "./ReceptionProsecutorDivisions/ReceptionProsecutorDivisions";

const Recepciones = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sorting, setSorting] = useState([]);

  const [receptions, setReceptions] = useState(undefined);

  const [prosecutor, setProsecutor] = useState({});
  const [division, setDivision] = useState({});
  const [complaintReportId, setComplaintReportId] = useState(undefined);
  const [typeReception, setTypeReception] = useState("");
  const [status, setStatus] = useState("");
  const [receivedAfter, setReceivedAfter] = useState("");
  const [receivedBefore, setReceivedBefore] = useState("");
  const [prosecutorDivisionId, setProsecutorDivisionId] = useState(undefined);
  const [prosecutorName, setProsecutorName] = useState(undefined);
  const [sigedeNumber, setSigedeNumber] = useState(undefined);

  const navigate = useNavigate();

  const [prosecutors, setProsecutors] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const { keycloak } = useKeycloak();

  const [view, setView] = useState("T");
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const reset = (e) => {
    e.preventDefault();
    setPage(1);
    setComplaintReportId("");
    setTypeReception("");
    setStatus("");
    setReceivedAfter("");
    setReceivedBefore("");
    setDivision("");
    setProsecutor("");
    setProsecutorName("");
    setSigedeNumber("");
    setSorting([]);
  };

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
        "Upgrade-Insecure-Requests": 1,
      },
    };
  };

  const findProsecutors = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/prosecutors`, prepareHeaders())
      .then((res) => {
        setProsecutors(res.status !== 200 ? [] : res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const findDivisions = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/divisions`, prepareHeaders())
      .then((res) => {
        setDivisions(res.status !== 200 ? [] : res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const findReceptions = () => {
    setLoading(true);
    let params = buildParams();
    const URL = `${process.env.REACT_APP_API_URL}/receptions?page=${page}&page_size=${pageSize}${params}`;
    axios
      .get(URL, prepareHeaders())
      .then((res) => {
        setReceptions(res.status === 206 ? res.data.content : []);
        setTotalPages(res.status === 206 ? res.data.totalPages : 10);
        setTotalElements(res.status === 206 ? res.data.totalElements : 0);
        setLoading(false);
        
      })
      .catch((err) => {
        console.error("Error fetching recepciones:", err);
        setReceptions([]);
        setLoading(false);
      });
  };

  const buildParams = () => {
    let prosecutorName = prosecutor.firstName;

    let query = "";
    if (complaintReportId) {
      query += `&complaint_report_id=${complaintReportId}`;
    }
    if (typeReception) {
      query += `&type_reception=${typeReception}`;
    }
    if (status) {
      query += `&status=${status}`;
    }
    if (receivedAfter) {
      query += `&received_after=${receivedAfter}`;
    }
    if (receivedBefore) {
      query += `&received_before=${receivedBefore}`;
    }
    if (division.id) {
      query += `&prosecutor_division_id=${division.id}`;
    }
    if (prosecutorName && prosecutorName !== "TODOS") {
      query += `&prosecutor_name=${prosecutorName}`;
    }
    if (sigedeNumber) {
      query += `&sigede_number=${sigedeNumber}`;
    }
    if (sorting && sorting.length > 0) {
      sorting.map((sort) => {
        query += `&sortBy=${sort.key}:${sort.direction}`;
      });
    }
    return query;
  };

  const sortingBy = (field, label) => {
    if (sorting.filter((element) => element.key === field).length > 0) {
      setSorting(sorting.filter((element) => element.key !== field));
    } else {
      setSorting([...sorting, { key: field, label, direction: "ASC" }]);
    }
  };

  const changeDirection = (sort) => {
    let elements = [];
    for (let current of sorting) {
      if (current.key === sort.key) {
        current.direction = sort.direction === "ASC" ? "DESC" : "ASC";
      }
      elements.push(current);
    }
    setSorting(elements);
  };

  const isDeputyProsecutor = () => {
    return checkRoles(keycloak) == "fiscal-adjunto";
  };

  const callback = () => {
    findReceptions();
  };

  useEffect(() => findReceptions(), [page, pageSize, sorting]);
  useEffect(() => {
    findReceptions();
  }, []);
  useEffect(
    () => setPage(1),
    [
      complaintReportId,
      typeReception,
      status,
      receivedAfter,
      receivedBefore,
      prosecutorDivisionId,
      prosecutorName,
      sigedeNumber,
    ]
  );
  useEffect(() => {
    findReceptions();
    findProsecutors();
    findDivisions();
  }, []);

  return (
    <>
      <Toaster />
      <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Col md={3}>
          <div className={styles.sideBar}>
            <Form>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingComplaintReportId"
                      type="text"
                      placeholder="Número de informe de queja"
                      className={styles.inputFloating}
                      value={complaintReportId}
                      onChange={(e) => setComplaintReportId(e.target.value)}
                    />
                    <label
                      htmlFor="floatingComplaintReportId"
                      className={styles.label}
                    >
                      N° de Reporte
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingTypeReception"
                      as="select"
                      value={typeReception}
                      className={styles.inputFloating}
                      onChange={(e) => {
                        setTypeReception(e.target.value);
                      }}
                      placeholder="Seleccione el tipo de recepción"
                    >
                      <option value="">Seleccione</option>
                      <option value="DNIT">DNIT</option>
                      <option value="CGR">CGR</option>
                      <option value="DENUNCIA">DENUNCIA</option>
                    </Form.Control>
                    <label
                      htmlFor="floatingTypeReception"
                      className={styles.label}
                    >
                      Tipo de recepción
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingStatus"
                      as="select"
                      value={status || ""}
                      placeholder="Estado de recepción"
                      className={styles.inputFloating}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <option value="">Seleccione</option>
                      <option value="RECEIVED">Recibido</option>
                      <option value="ASSIGNED">Asignado</option>
                      <option value="NO_COMPETENCE">Sin Competencia</option>
                    </Form.Control>
                    <label htmlFor="floatingStatus" className={styles.label}>
                      Estado de recepción
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingReceivedAfter"
                      className={styles.input}
                      type="date"
                      value={receivedAfter}
                      onChange={(e) => {
                        setReceivedAfter(e.target.value);
                      }}
                    />
                    <label
                      htmlFor="floatingReceivedAfter"
                      className={styles.label}
                    >
                      Fecha de recepción desde
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingReceivedBefore"
                      className={styles.input}
                      type="date"
                      value={receivedBefore}
                      onChange={(e) => {
                        setReceivedBefore(e.target.value);
                      }}
                    />
                    <label
                      htmlFor="floatingReceivedBefore"
                      className={styles.label}
                    >
                      Fecha de recepción hasta
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Select
                      id="floatingDivision"
                      className={styles.inputFloating}
                      value={JSON.stringify(division)}
                      onChange={(e) => {
                        setDivision(JSON.parse(e.target.value));
                      }}
                    >
                      <option
                        value={JSON.stringify({
                          prosecutorDivisionId: "TODOS",
                        })}
                        selected
                      >
                        TODOS
                      </option>
                      {divisions.map((division) => (
                        <option
                          value={JSON.stringify(division)}
                        >{`${division.id} ${division.name}`}</option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingDivision" className={styles.label}>
                      Unidad Fiscal
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>

              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Select
                      id="floatingFiscal"
                      className={styles.inputFloating}
                      value={JSON.stringify(prosecutor)}
                      onChange={(e) => {
                        setProsecutor(JSON.parse(e.target.value));
                      }}
                    >
                      <option
                        value={JSON.stringify({ firstName: "TODOS" })}
                        selected
                      >
                        TODOS
                      </option>
                      {prosecutors.map((prosecutor) => (
                        <option
                          value={JSON.stringify(prosecutor)}
                        >{`${prosecutor.firstName} ${prosecutor.lastName}`}</option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingFiscal" className={styles.label}>
                      Fiscal
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingSigedeNumber"
                      className={styles.input}
                      type="text"
                      value={sigedeNumber}
                      onChange={(e) => {
                        setSigedeNumber(e.target.value);
                      }}
                    />
                    <label
                      htmlFor="floatingSigedeNumber"
                      className={styles.label}
                    >
                      Número de SIGEDE
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: "1rem" }}>
                <Col
                  style={{
                    textAlign: "right",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <DropdownButton
                    bsPrefix={styles.secondary}
                    title="Ordenar"
                    style={{ marginLeft: "auto" }}
                  >
                    <Dropdown.Item
                      onClick={(e) =>
                        sortingBy("sigede_number", "Número de SIGEDE")
                      }
                      className={styles.item}
                    >
                      Nº de SIGEDE
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) =>
                        sortingBy(
                          "prosecutor_divisions[0].prosecutor_division_name",
                          "Unidad Fiscal"
                        )
                      }
                      className={styles.item}
                    >
                      Unidad Fiscal
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) =>
                        sortingBy("complaint_report_id", "Número de reporte")
                      }
                      className={styles.item}
                    >
                      N° de Reporte
                    </Dropdown.Item>
                  </DropdownButton>
                  &nbsp;&nbsp;
                  <Button onClick={reset} bsPrefix={styles.primary}>
                    Limpiar <AiOutlineClear />
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => findReceptions()}
                    bsPrefix={styles.primary}
                  >
                    Buscar <AiOutlineSearch />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col md={9}>
          <div className={styles.container}>
            <h2>Recepciones</h2>
            <Row>
              <Col>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/denuncias/registrar");
                  }}
                  bsPrefix={styles.newPrimary}
                  style={{ marginLeft: "auto" }}
                >
                  &nbsp;&nbsp;Nueva Recepción &nbsp;&nbsp;
                  <FaFileCirclePlus />
                  &nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
            <Row style={{ flexDirection: "row-reverse" }}>
              <Col md="auto" style={{ textAlign: "right" }}>
                <InputGroup
                  style={{
                    flexWrap: "wrap",
                    alignItems: "stretch",
                    justifyContent: "flex-end",
                  }}
                >
                  <RiTableFill
                    style={{ marginTop: "auto" }}
                    className={
                      view == "T" ? styles.iconActive : styles.iconInactive
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setView("T");
                      setPageSize(20);
                      setPage(1);
                    }}
                  />
                  &nbsp;
                  <RiArticleFill
                    style={{ marginTop: "auto" }}
                    className={
                      view == "C" ? styles.iconActive : styles.iconInactive
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setView("C");
                      setPageSize(2);
                      setPage(1);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col md="auto" style={{ textAlign: "right" }}>
                {sorting.map((sort) => (
                  <>
                    <Badge className={styles.sortItem}>
                      {sort.label}
                      &nbsp;
                      {sort.direction === "ASC" ? (
                        <RiSortAsc
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            changeDirection(sort);
                          }}
                        />
                      ) : (
                        <RiSortDesc
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            changeDirection(sort);
                          }}
                        />
                      )}
                      &nbsp;
                      <RiCloseFill
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          sortingBy(sort.key);
                        }}
                      />
                    </Badge>
                    &nbsp;
                  </>
                ))}
              </Col>
            </Row>
            <Row style={{ marginTop: ".5rem", marginBottom: "1.5rem" }}>
              <Col md={6}>
                <CustomPagination
                  page={page}
                  pageSize={pageSize}
                  changePageSize={setPageSize}
                  toPage={(page) => {
                    setPage(page);
                  }}
                  totalPages={totalPages}
                />
              </Col>
              <Col md={6} style={{ textAlign: "right", marginTop: "auto" }}>
                <span>
                  {totalElements}{" "}
                  {totalElements > 1 ? "recepciones" : "recepción"}
                </span>
              </Col>
            </Row>

            {loading ? (
              <span className="mt-3 mb-2">Buscando...</span>
            ) : !loading && (!receptions || receptions.length == 0) ? (
              <span className="mt-3 mb-2">No hay resultados</span>
            ) : (
              <>
                {view == "T" ? (
                  <Table className={styles.table}>
                    <thead>
                      <th>Recepcionado por</th>
                      <th>Estado</th>
                      <th>Fecha de recepción</th>
                      <th>Acciones</th>
                    </thead>
                    <tbody>
                      {receptions.map((reception, index) => (
                        <tr key={index}>
                          <td>{reception.type_reception}</td>
                          <td>
                            {reception.status === "RECEIVED"
                              ? "Recibido"
                              : reception.status === "ASSIGNED"
                              ? "Asignado"
                              : reception.status === "NO_COMPETENCE"
                              ? "Sin Competencia"
                              : "Desconocido"}
                          </td>
                          <td>{reception.received_date}</td>
                          <td>
                            <Button
                              bsPrefix={styles.next}
                              onClick={(e) => {
                                let route = "";
                                if (reception.type_reception === "DENUNCIA") {
                                  route = `/denuncias/${reception.complaint_report_id}`;
                                } else if (reception.type_reception === "CGR") {
                                  route = `/cgr/${reception.complaint_report_id}`;
                                } else if (
                                  reception.type_reception === "DNIT"
                                ) {
                                  route = `/dnit/${reception.complaint_report_id}`;
                                }
                                navigate(route);
                              }}
                            >
                              VER
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  receptions &&
                  receptions.map(
                    (reception) =>
                      reception && (
                        <ReceptionProsecutorDivisions
                          key={reception.reception_id || Math.random()}
                          callback={() => findReceptions()}
                          data={reception}
                        />
                      )
                  )
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Recepciones;
