import React from "react";
import { Form, Col, Row, Table, Badge } from "react-bootstrap";
import styles from "./Steps.module.scss";
import { useKeycloak } from "@react-keycloak/web";
import { JudgmentType } from "../../../../constants/JudgmentType";

const Step7 = ( { next, prev, data } ) => {

    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        };
    }    
    
    const downloadFile = (e, file) => {
        e.preventDefault();        
        let indiceInicioDatos = file.payload.indexOf('base64,') + 7;
        let payload = file.payload.substring(indiceInicioDatos);
        let byteCharacters = atob(payload);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            let slice = byteCharacters.slice(offset, offset + 1024);        
            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) { byteNumbers[i] = slice.charCodeAt(i); }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        let blob = new Blob(byteArrays, { type: 'application/octet-stream' });
        var url = URL.createObjectURL(blob);    
        var link = document.createElement('a');
        link.href = url;
        link.download = file.fileName;
        link.target = "_blank";
        link.click();
    }

    return (
        <>

            <Form >
                <Form.Group className={styles.group}>
                    <Form.Label className={styles.groupLabel}>Dictámenes</Form.Label>
                    {
                        data.judgments && data.judgments.length > 0 ? (
                            <Row>
                                <Col md={12}>
                                    <Table className={styles.table} style={{ marginTop: "0.2rem" }}>
                                        <thead>
                                            <th>Responsable</th>
                                            <th style={{ textAlign: "center" }}>Dictamen</th>
                                            <th>Descripción</th>
                                            <th style={{ textAlign: "center" }}>Adjuntos</th>
                                            <th>Creado el</th>
                                        </thead>
                                        <tbody>
                                            {
                                                data.judgments.map((judgment) => (
                                                    <tr>
                                                        <td>{`${judgment.createdBy.firstName} ${judgment.createdBy.lastName}`}</td>
                                                        <td style={{ paddingRight: "0px", textAlign: "center" }}>
                                                            <Badge className={styles.judgmentType} pill bg={JudgmentType[judgment.judgmentType].bg}>{JudgmentType[judgment.judgmentType].name}</Badge>
                                                        </td>
                                                        <td>{judgment.description}</td>
                                                        <td style={{ paddingRight: "0px", textAlign: "center" }}>
                                                            { 
                                                                judgment.file
                                                                ? 
                                                                ( 
                                                                    <Badge className={styles.judgmentFile} pill bg="light" text="dark" onClick={(e) => downloadFile(e, judgment.file)}>{judgment.file.fileName}</Badge>
                                                                ) 
                                                                : 
                                                                <>-</> 
                                                            }
                                                        </td>
                                                        <td>{judgment.createdAt}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>                                    
                                </Col>
                            </Row>
                        )
                        : 
                        <Row>
                            <Form.Label className={styles.noContent}>No hay elementos</Form.Label>
                        </Row>
                    }                    
                </Form.Group>
            </Form>
        </>
    );

}

export default Step7;