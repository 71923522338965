import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { GiInjustice } from "react-icons/gi";
import { IoMdDocument } from "react-icons/io";
import { BsFillBellFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { RiChatCheckFill, RiChatDeleteFill } from "react-icons/ri";
import { MdAssignmentAdd, MdAssignmentLate } from "react-icons/md";
import { AiFillHome } from "react-icons/ai";
import { BiAnalyse } from "react-icons/bi";
import { MdAdminPanelSettings } from "react-icons/md";
import { RoleEnum } from "../../constants/RoleEnum";
import axios from "axios";
import styles from "./Header.module.css";
import { checkRoles, roles } from "../../Keycloak";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import toast, { Toaster } from "react-hot-toast";
import { calcTime } from "../../core/time";
import { Complexity } from "../../constants/Complexity";
import { SiGoogleanalytics } from "react-icons/si";
import { AlertType } from "../../constants/AlertType";
import { DisassociationReply } from "../../constants/DisassociationReply";

const Header = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const [alerts, setAlerts] = useState([]);
  const [news, setNews] = useState(0);

  const navigate = useNavigate();
  // const socket = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
  //   extraHeaders: {
  //     "Access-Control-Allow-Origin": "*"
  //   }
  // });

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const findAlerts = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/alerts?page=1&pageSize=6`,
        prepareHeaders()
      )
      .then((res) => {
        setAlerts(res.status == 204 ? [] : res.data.content);
        setNews(res.status == 204 ? 0 : res.data.news);
      })
      .catch((err) => {
        setAlerts([]);
        setNews(0);
      });
  };

  const resolveAction = (alert) => {
    switch (alert.type) {
      case "ANALYSIS":
        findAlerts();
        navigate(`/denuncias/consultar?id=${alert.payload.complaint.id}`);
        navigate(0);
      case "PRINCIPAL_PROSECUTOR_ASSIGNATION":
        findAlerts();
        navigate(`/causas/${alert.payload.id}`);
        navigate(0);
      default:
        findAlerts();
        navigate(`/causas/${alert.payload.cause.id}`);
        navigate(0);
    }
  };

  const view = (e, alert) => {
    e.preventDefault();
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/alerts/${alert.id}`,
        null,
        prepareHeaders()
      )
      .then((res) => {
        resolveAction(alert);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showAlert = (alert) => {
    switch (alert.type) {
      case "ANALYSIS":
        return (
          <Dropdown.Item
            className={`${
              !alert.viewed ? styles.alertItemViewed : styles.alertItemContainer
            }`}
            onClick={(e) => {
              view(e, alert);
            }}
          >
            <Row>
              <Col md="auto">
                <BiAnalyse className={styles.alertItemIcon} />
              </Col>
              <Col md={8}>
                <Row>
                  <Col>
                    <span className={styles.alertItemTitle}>
                      Análisis finalizado
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className={styles.alertItemDetail}>
                      {`Denuncia #${alert.payload.complaint.id} 
                          ${
                            alert.payload.result.result
                              ? "analizada como de competencia con una tasa del " +
                                alert.payload.result.accuracy +
                                "%"
                              : "analizada como de no competencia con una tasa del " +
                                alert.payload.result.accuracy +
                                "%"
                          }
                          `}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col md="auto">
                <span className={styles.alertItemTime}>
                  {calcTime(alert.createdAt, false)}
                </span>
              </Col>
            </Row>
          </Dropdown.Item>
        );
      case "COMPLEXITY":
        return (
          <Dropdown.Item
            className={`${
              !alert.viewed ? styles.alertItemViewed : styles.alertItemContainer
            }`}
            onClick={(e) => {
              view(e, alert);
            }}
          >
            <Row>
              <Col md="auto">
                <SiGoogleanalytics className={styles.alertItemIcon} />
              </Col>
              <Col md={8}>
                <Row>
                  <Col>
                    <span className={styles.alertItemTitle}>
                      Análisis finalizado
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className={styles.alertItemDetail}>
                      {`Denuncia #${
                        alert.payload.cause.complaint.id
                      } analizada como de complejidad ${
                        Complexity[alert.payload.analysis.level].name
                      } con un total de ${
                        alert.payload.analysis.points
                      } puntos`}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col md="auto">
                <span className={styles.alertItemTime}>
                  {calcTime(alert.createdAt, false)}
                </span>
              </Col>
            </Row>
          </Dropdown.Item>
        );
      case "PRINCIPAL_PROSECUTOR_ASSIGNATION":
        return (
          <Dropdown.Item
            className={`${
              !alert.viewed ? styles.alertItemViewed : styles.alertItemContainer
            }`}
            onClick={(e) => {
              view(e, alert);
            }}
          >
            <Row>
              <Col md="auto">
                <MdAssignmentAdd className={styles.alertItemIcon} />
              </Col>
              <Col md={8}>
                <Row>
                  <Col>
                    <span className={styles.alertItemTitle}>
                      Causa asignada
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span
                      className={styles.alertItemDetail}
                    >{`Se le ha sido asignada la causa #${alert.payload.id}`}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className={styles.alertItemDetail}>
                      {alert.payload.cover}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col md="auto">
                <span className={styles.alertItemTime}>
                  {calcTime(alert.createdAt, false)}
                </span>
              </Col>
            </Row>
          </Dropdown.Item>
        );
      case "DISASSOCIATION_REQUEST":
        return (
          <Dropdown.Item
            className={`${
              !alert.viewed ? styles.alertItemViewed : styles.alertItemContainer
            }`}
            onClick={(e) => {
              view(e, alert);
            }}
          >
            <Row>
              <Col md="auto">
                <MdAssignmentLate className={styles.alertItemIcon} />
              </Col>
              <Col md={8}>
                <Row>
                  <Col>
                    <span className={styles.alertItemTitle}>
                      Solicitud de inhibición recibida
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span
                      className={styles.alertItemDetail}
                    >{`Fiscal: ${alert.payload.cause.principalProsecutor.firstName} ${alert.payload.cause.principalProsecutor.lastName}`}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span
                      className={styles.alertItemDetail}
                    >{`${alert.payload.cause.cover}`}</span>
                  </Col>
                </Row>
              </Col>
              <Col md="auto">
                <span className={styles.alertItemTime}>
                  {calcTime(alert.createdAt, false)}
                </span>
              </Col>
            </Row>
          </Dropdown.Item>
        );
      case "DISASSOCIATION_REPLY":
        return (
          <Dropdown.Item
            className={`${
              !alert.viewed ? styles.alertItemViewed : styles.alertItemContainer
            }`}
            onClick={(e) => {
              view(e, alert);
            }}
          >
            <Row>
              <Col md="auto">
                {alert.payload.reply.judgment == "APPROVED" ? (
                  <RiChatCheckFill className={styles.alertItemIcon} />
                ) : (
                  <RiChatDeleteFill className={styles.alertItemIcon} />
                )}
              </Col>
              <Col md={8}>
                <Row>
                  <Col>
                    <span className={styles.alertItemTitle}>
                      Solicitud de inhibición{" "}
                      {DisassociationReply[alert.payload.reply.judgment].name}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span
                      className={styles.alertItemDetail}
                    >{`Causa #${alert.payload.cause.id}`}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className={styles.alertItemDetail}>
                      {alert.payload.cause.cover}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col md="auto">
                <span className={styles.alertItemTime}>
                  {calcTime(alert.createdAt, false)}
                </span>
              </Col>
            </Row>
          </Dropdown.Item>
        );
      default:
        return <></>;
    }
  };

  // useEffect(() => {
  //   let role = checkRoles(keycloak);
  //   console.log(keycloak.idTokenParsed.sub);
  //   socket.on(keycloak.idTokenParsed.sub, ( data ) => {
  //     console.log(data);
  //     let message = JSON.parse(data);
  //     toast.success(AlertType[message.type].name, { position: "top-right"});
  //     findAlerts();
  //   });
  //   switch(role){
  //     case "fiscal-adjunto":
  //       socket.on('complaint.analyzed', ( data ) => {
  //         let message = JSON.parse(data);
  //         toast.success("Análisis finalizado", { id: message.payload.complaint.id, position: "top-right"});
  //         findAlerts();
  //       });
  //       socket.on('complaint.received', ( data ) => {
  //         let message = JSON.parse(data);
  //         toast("Denuncia recibida", { id: message.payload.complaint.id, position: "top-right"});
  //         findAlerts();
  //       });
  //       socket.on('cause.disassociation.requested', ( data ) => {
  //         let message = JSON.parse(data);
  //         toast("Solicitud de inhibición recibida", { position: "top-right" });
  //         findAlerts();
  //       });
  //       break;
  //     case "mesa-de-entrada":
  //       break;
  //     case "fiscal":
  //       break;
  //     default:
  //       break;
  //   }
  //   findAlerts();
  // }, [initialized]);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <div style={{ display: "flex" }}>
              <Logo width={100} height={50} />
              <Link to="/" className={styles.brandLabel}>
                {" "}
                UNIDAD ESPECIALIZADA <br />
                DE DELITOS ECONÓMICOS Y ANTICORRUPCIÓN{" "}
              </Link>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {
                // !roles(keycloak).every(role => role == "admin") ?
                true ? (
                  <Nav.Link
                    onClick={(e) => {
                      navigate("/");
                    }}
                    className={styles.iconGroup}
                  >
                    <AiFillHome className={styles.icon} />
                    <span className={styles.iconLabel}>Inicio</span>
                  </Nav.Link>
                ) : (
                  <></>
                )
              }
              {
                // roles(keycloak).includes("admin") ?
                true ? (
                  <Nav.Link
                    onClick={(e) => {
                      navigate("/admin");
                    }}
                    className={styles.iconGroup}
                  >
                    <MdAdminPanelSettings className={styles.icon} />
                    <span className={styles.iconLabel}>Administrar</span>
                  </Nav.Link>
                ) : (
                  <></>
                )
              }
              {
                // roles(keycloak).includes("mesa-de-entrada") || roles(keycloak).includes("fiscal-adjunto") || roles(keycloak).includes("ddea") || roles(keycloak).includes("external-institution") ?
                true ? (
                  <Dropdown
                    style={{
                      backgroundColor: "none",
                      color: "none",
                      border: "none",
                    }}
                  >
                    <Dropdown.Toggle
                      style={{
                        background: "none",
                        border: "none",
                        display: "grid",
                        textAlign: "center",
                        marginRight: "1rem",
                      }}
                    >
                      <IoMdDocument className={styles.icon} />
                      <span className={styles.iconLabel}>Denuncias</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          navigate("/cgr/consultar");
                        }}
                      >
                        <IoMdDocument className={styles.icon} />
                        Contraloria
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          navigate("/denuncias/consultar");
                        }}
                      >
                        <IoMdDocument className={styles.icon} />
                        Denuncias
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          navigate("/dnit/consultar");
                        }}
                      >
                        <IoMdDocument className={styles.icon} />
                        DNIT
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )
              }
              {
                // roles(keycloak).includes("fiscal") || roles(keycloak).includes("fiscal-adjunto") || roles(keycloak).includes("asistente-fiscal") ?
                true ? (
                  <Nav.Link
                    onClick={(e) => {
                      navigate("/causas");
                    }}
                    className={styles.iconGroup}
                  >
                    <GiInjustice className={styles.icon} />
                    <span className={styles.iconLabel}>Causas</span>
                  </Nav.Link>
                ) : (
                  <></>
                )
              }

              <Dropdown>
                <Dropdown.Toggle className={styles.option} variant="secondary">
                  <div style={{ position: "relative", marginBottom: "3px" }}>
                    <BsFillBellFill className={styles.alertIcon} />
                    {alerts && alerts.length > 0 ? (
                      <Badge className={styles.alert} bg="danger">
                        {news}
                      </Badge>
                    ) : (
                      <></>
                    )}
                  </div>
                  <span className={styles.iconLabel}>Alertas</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.menuGroup}>
                  {alerts && alerts.length > 0 ? (
                    alerts.map((alert) => showAlert(alert))
                  ) : (
                    <></>
                  )}
                  <Dropdown.Divider className={styles.divider} />
                  <Dropdown.Item
                    onClick={(e) => {
                      navigate("/alertas");
                    }}
                    className={styles.alertVerMas}
                  >
                    Ver más resultados
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
          {keycloak.authenticated ? (
            <Navbar.Collapse className="ms-auto" id="responsive-navbar-nav">
              <Dropdown className="ms-auto">
                <Dropdown.Toggle className={styles.userContainer}>
                  <Row>
                    <Col>
                      <FaUserCircle style={{ width: "40px", height: "100%" }} />
                    </Col>
                    <Col>
                      <Row>
                        <span className={styles.userName}>
                          {keycloak.tokenParsed.name}
                        </span>
                      </Row>
                      <Row>
                        <span className={styles.userRole}>
                          {roles(keycloak)
                            .map((role) => RoleEnum[role].name)
                            .join(" / ")}
                        </span>
                      </Row>
                    </Col>
                  </Row>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      keycloak.logout();
                    }}
                  >
                    Cerrar sesión
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Collapse>
          ) : (
            <></>
          )}
        </Container>
      </Navbar>
      <Toaster />
    </>
  );
};

export default Header;
