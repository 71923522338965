import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./OfficialModal.module.scss";

const OfficialModal = ({
  show,
  handleClose,
  callback,
  data,
  add,
  currentOfficialIndex
}) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [currentContactIndex, setCurrentContactIndex] = useState(0);

  const [official, setOfficial] = useState({});
  
  const { keycloak } = useKeycloak();

  const handleNextContacts = () => {
    if (
      data &&
      data.officials &&
      currentOfficialIndex !== undefined &&
      currentOfficialIndex < data.officials.length &&
      currentContactIndex < data.officials[currentOfficialIndex].contact.length - 1
    ) {
      setCurrentContactIndex((prevIndex) => prevIndex + 1);
    }
  };
  
  const handlePreviousContacts = () => {
    if (currentContactIndex > 0) {
      setCurrentContactIndex((prevIndex) => prevIndex - 1);
    }
  };

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  useEffect(() => {
    if (!add) {
      if (
        data &&
        data.officials &&
        currentOfficialIndex !== undefined &&
        data.officials[currentOfficialIndex] !== undefined
      ) {
        const currentOfficial = data.officials[currentOfficialIndex];
        
        const currentContact = currentOfficial.contact[currentContactIndex] || {};
            
        setOfficial({
          firstName: currentOfficial.firstName,
          lastName: currentOfficial.lastName,
          rol: currentOfficial.rol,
          contact: [{
            type: currentContact.type,
            contactValue: currentContact.contact_value
          }]
        });
      }
    } else {
      const currentOfficial = data.officials[currentOfficialIndex];
      setOfficial({
        firstName: "",
        lastName: "",
        rol: "",
        contact: [{
          type: "",
          contact_value: ""
        }]
      });
    }

  }, [data, currentOfficialIndex, currentContactIndex, add]);

  const buildBody = () => {
    const currentContact =
    official.contact[currentContactIndex] || {
      type: "",
      contactValue: "",
    };

    return {
      firstName: official.firsName,
      lastName: official.lastName,
      rol: official.rol,
      contact:[{
        type: currentContact.type,
        contact_value: currentContact.contactValue,
      }]
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/contraloria/${data.report_contraloria_id}/officials/${data.official[currentOfficialIndex].official_id}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        callback();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
      });
  };

  const handleInputChange = (field, value) => {
    setOfficial((prevOfficial) => ({
      ...prevOfficial,
      [field]: value,
    }));
  };

  useEffect(() => {
    setCurrentContactIndex(0);
  }, [currentOfficialIndex]);

  return (data.officials[currentOfficialIndex] !== undefined &&
    official !== undefined) ||
    add === true ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {add ? "Agregar funcionario" : "Funcionarios"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6>Funcionario {currentOfficialIndex + 1}</h6>
            <Row className="mt-2 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    value={official.firstName}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("firstName", e.target.value)
                    }
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingFirstName"
                    className={styles.label}
                  >
                    Nombre
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    type="text"
                    value={official.lastName}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("lastName", e.target.value)
                    }
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingLastName"
                    className={styles.label}
                  >
                    Apellido
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    type="text"
                    value={official.rol}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("rol", e.target.value)
                    }
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingRol"
                    className={styles.label}
                  >
                    Rol
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                  <Row className="align-items-center">
                    <Col>
                      <Form.Label>
                        Contacto{" "}
                        {data.officials[currentOfficialIndex].contact.length > 0 && (
                          <>
                            {" "}
                            {currentContactIndex + 1} de{" "}
                            {data.officials[currentOfficialIndex].contact.length}
                          </>
                        )}
                      </Form.Label>
                    </Col>
                  </Row>
                  {data.officials[currentOfficialIndex].contact.length > 0 && (
                    <>
                      <Row style={{ marginBottom: "1rem" }}>
                        <Col>
                          <Form.Group as={Col}>
                            <Form.Floating>
                              <Form.Control
                                required
                                type="text"
                                value={data.officials[currentOfficialIndex].contact[currentContactIndex].type}
                                className={styles.input}
                                readOnly
                              />
                              <Form.Label
                                htmlFor="floatingRol"
                                className={styles.label}
                              >
                                Tipo de contacto
                              </Form.Label>
                            </Form.Floating>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Col}>
                            <Form.Floating>
                              <Form.Control
                                required
                                type="text"
                                value={data.officials[currentOfficialIndex].contact[currentContactIndex].contact_value}
                                className={styles.input}
                                readOnly
                              />
                              <Form.Label
                                htmlFor="floatingRol"
                                className={styles.label}
                              >
                                Contacto
                              </Form.Label>
                            </Form.Floating>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                {data.officials[currentOfficialIndex].contact.length > 1 && (
                    <Row style={{ marginTop: "1rem" }}>
                      <Col xs="auto">
                        <Button
                          className="boton-over"
                          variant="secondary"
                          onClick={handlePreviousContacts}
                          disabled={currentContactIndex === 0}
                        >
                          {"<"}
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <Button
                          className="boton-over"
                          variant="secondary"
                          onClick={handleNextContacts}
                          disabled={
                            currentContactIndex ===
                            data.officials[currentOfficialIndex].contact.length - 1
                          }
                        >
                          {">"}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Row>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};
export default OfficialModal;
