import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  Table,
  Modal,
  Dropdown,
} from "react-bootstrap";
import styles from "../DenunciaForm.module.scss";
import { useKeycloak } from "@react-keycloak/web";
import { FiTrash } from "react-icons/fi";
import { AiOutlineFileSearch } from "react-icons/ai";
import axios from "axios";
import DenunciantesModal from "../DenunciantesModal/DenunciantesModal";
import { GoPersonAdd } from "react-icons/go";
import { BiSolidInstitution } from "react-icons/bi";
import toast from "react-hot-toast";
import { IoPersonAdd, IoPersonCircleOutline } from "react-icons/io5";
import InstitucionForm from "../institucion/InstitucionForm";
import ComplainsGrid from "../Complains";
import { FaScaleBalanced } from "react-icons/fa6";
import { set } from "rsuite/esm/utils/dateUtils";
import MatriculaNumberModal from "../../modals/MatriculaNumberModal";
import AgentModal from "../../modals/AgentGridModal";
import AddAgentModal from "../../modals/AddAgentModal";
import AddPersonModal from "../../modals/AddPersonModal";
import AddInstitutionModal from "../../modals/AddInstitutionModal";
import PersonModal from "../../modals/PersonModal/PersonModal";
import InstitutionModal from "../../modals/InstitutionModal/InstitutionModal";

const Denunciantes = ({ next, previous, complainants, setComplainants }) => {
  const [validated, setValidated] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [type, setType] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState("");
  const [selectedInstitutionName, setSelectedInstitutionName] = useState("");
  const [mandatorio, setMandatorio] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedMatricula, setSelectedMatricula] = useState("");
  const [person, setPerson] = useState({
    id: null,
    documentType: "CI",
    documentNumber: null,
    firstName: "",
    lastName: "",
    birthDate: null,
    gender: "",
    maritalStatus: "",
    profession: "",
    alias: "",
    matriculaNumber: "",
    addresses: [],
    contacts: [],
    personInstitutions: [],
    agents: [],
  });
  const [isDetail, setIsDetail] = useState(false);

  const { keycloak } = useKeycloak();
  //Modals
  const [showModal, setShowModal] = useState(false);
  const [showModalInstitution, setShowModalInstitution] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  const [isAddAgent, setIsAddAgent] = useState(false);
  const [isCreateInstitution, setIsCreateInstitution] = useState(false);
  const [matriculaNumber, setMatriculaNumber] = useState(false);
  const [isDetailInstitution, setIsDetailInstitution] = useState(false);

  const types = [
    {
      label: "Ciudadano",
      value: "PF",
    },
    // {
    //     label: "Institución del Estado",
    //     value: "IE"
    // },
    {
      label: "Institución privada",
      value: "IP",
    },
  ];

  const handleNext = (e) => {
    e.preventDefault();
    if (!complainants) {
      return toast.error("Debe agregar al menos un denunciante", {
        position: "bottom-right",
      });
    }
    if (!complainants || complainants.length === 0) {
      return toast.error("Debe agregar al menos un denunciante", {
        position: "bottom-right",
      });
    }
    if (complainants.some((complainant) => complainant.ruc)) {
      if (
        !complainants.some(
          (complainant) => complainant.agents && complainant.agents.length > 0
        )
      ) {
        return toast.error("Debe agregar al menos un representante", {
          position: "bottom-right",
        });
      }
      if (
        complainants.some(
          (complainant) => complainant.agents && complainant.agents.length === 0
        )
      ) {
        return toast.error(
          "Debe agregar al menos un representante para cada denunciante",
          {
            position: "bottom-right",
          }
        );
      }
    }

    if (
      complainants.some(
        (complainant) =>
          complainant.agents &&
          complainant.agents.some((agent) => !agent.matriculaNumber)
      )
    ) {
      console.log(complainants);
      return toast.error("El representante debe tener matricula", {
        position: "bottom-right",
      });
    }
    next(complainants);
  };

  const skip = (e) => {
    e.preventDefault();
    next([]);
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    previous(complainants);
  };

  const handleSubmit = async () => {
    await uploadFiles();
  };

  const prepareFileHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  };

  const uploadFiles = async () => {
    if (person.documentNumber.length > 0) {
      createPerson();
    }
    if (mandatorio && attachments && attachments.length > 0) {
      if (attachments.filter((file) => file.id).length === 0) {
        let formData = new FormData();
        for (let file of attachments) {
          formData.append("files", file);
        }
        await axios
          .post(
            process.env.REACT_APP_API_URL + "/files",
            formData,
            prepareFileHeaders()
          )
          .then((res) => {
            let aux = [];
            for (let current of attachments) {
              for (const saved of res.data) {
                if (
                  current.name === saved.fileName.replace(`${saved.id}-`, "")
                ) {
                  current.id = saved.id;
                  break;
                }
              }
              aux.push(current);
            }
            setComplainants([...complainants, person]);
            handleNext();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setComplainants([...complainants, person]);
        handleNext();
      }
    } else {
      setComplainants([...complainants, person]);
      handleNext();
    }
  };

  const removeComplainant = (e, complainant) => {
    e.preventDefault();
    let aux = [];
    for (let current of complainants) {
      if (current !== complainant) {
        aux.push(current);
      }
    }
    setComplainants(aux);
  };

  const changeType = (e) => {
    setType(e.target.value);
    setMandatorio(e.target.value === "IP");
  };

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const deleteAgents = async (agentDocId) => {
    setPerson((prevAgents) => ({
      ...prevAgents,
      agents: Array.isArray(prevAgents.agents)
        ? prevAgents.agents.filter(
            (agent) => agent.documentNumber !== agentDocId
          )
        : [],
    }));

    const result = await complainants.map((complainant) => {
      complainant.agents = complainant.agents.filter(
        (agent) => agent.documentNumber !== agentDocId
      );
      return complainant;
    });

    setComplainants(result);
  };

  const addAgents = async (person, agent) => {
    if (!agent || !agent.documentNumber) {
      console.error("Agente inválido");
      return;
    }
    await setComplainants((prevComplainants) =>
      prevComplainants.map((prevComplainant) => {
        if (prevComplainant.documentNumber === person.documentNumber) {
          const updatedAgents = prevComplainant.agents
            ? [...prevComplainant.agents, agent]
            : [agent];
          return { ...prevComplainant, agents: updatedAgents };
        }
        return prevComplainant;
      })
    );
    await setPerson((prevPerson) => {
      const updatedAgents = prevPerson.agents
        ? [...prevPerson.agents, agent]
        : [agent];
      return { ...prevPerson, agents: updatedAgents };
    });
    setIsAddAgent(false);
    setSelectedPerson(agent);
    setMatriculaNumber(true);
  };

  const createPerson = () => {
    const URL = `${process.env.REACT_APP_API_URL}/person`;
    axios
      .post(URL, person, prepareHeaders())
      .then((res) => {
        toast.success("Persona registrada exitosamente", {
          position: "top-center",
        });
        setPerson({ ...person, id: res.data.id });
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar la persona ", {
          position: "top-center",
        });
        console.log(err);
      });
  };

  const updateMatricula = (Agent) => {
    if (!Agent) return;
    if (selectedMatricula === "") return;
    try {
      setComplainants(
        complainants.map((complainant) => {
          return {
            ...complainant,
            agents: complainant.agents.map((agent) => {
              if (agent.documentNumber === Agent.documentNumber) {
                return { ...agent, matriculaNumber: selectedMatricula };
              }
              return agent;
            }),
          };
        })
      );
      setPerson((prevPerson) => ({
        ...prevPerson,
        agents: prevPerson.agents.map((agent) => {
          if (agent.documentNumber === Agent.documentNumber) {
            return { ...agent, matriculaNumber: selectedMatricula };
          }
          return agent;
        }),
      }));
    } catch (error) {
      return toast.error("Ocurrio un error al agregar matricula", {
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (
      showModal == false &&
      showModalInstitution == false &&
      isAgent == false
    ) {
      setSelectedPerson("");
      setSelectedInstitutionName("");
    }
  }, [showModal, showModalInstitution, isAgent]);
  useEffect(() => {
    if (matriculaNumber === false) {
      updateMatricula(selectedPerson);
      setSelectedPerson("");
    }
  }, [matriculaNumber]);

  return (
    <>
      {/* AddAgentModal */}
      <AddAgentModal
        show={isAddAgent}
        onHide={() => {
          setIsAddAgent(false);
          setSelectedPerson("");
          setIsAgent(true);
        }}
        setIsCreate={setIsCreate}
        addAgents={addAgents}
        person={person}
        setPerson={setPerson}
        isAddAgent={isAddAgent}
        setIsAddAgent={setIsAddAgent}
      />

      {/* AgentModal */}
      <AgentModal
        show={isAgent}
        handleClose={() => setIsAgent(false)}
        setIsAddAgent={setIsAddAgent}
        person={person}
        deleteAgents={deleteAgents}
        setSelectedPerson={setSelectedPerson}
        setMatriculaNumber={setMatriculaNumber}
        setSelectedMatricula={setSelectedMatricula}
      />

      {/* // ModalMatricula */}
      <MatriculaNumberModal
        show={matriculaNumber}
        onHide={() => {
          setMatriculaNumber(false);
        }}
        selectedMatricula={selectedMatricula}
        setSelectedMatricula={setSelectedMatricula}
      />

      {/* CreatePersonModal */}
      <DenunciantesModal
        showModal={isCreate}
        onHide={() => {
          setIsCreate(false);
          setPerson({});
          setShowModal(true);
        }}
        setModal={setShowModal}
        setPerson={setPerson}
        handleSubmit={uploadFiles}
        type={type}
        setMandatorio={setMandatorio}
        mandatorio={mandatorio}
        setAttachments={setAttachments}
        attachments={attachments}
        person={person}
      />

      {/* PersonDetailsModal */}
      <PersonModal
        show={isDetail}
        onHide={() => {
          setIsDetail(false);
        }}
        personId={person.personId}
      />

      <InstitutionModal
        show={isDetailInstitution}
        onHide={() => {
          setIsDetailInstitution(false);
        }}
        institutionId={person.institution_id}
      />

      {/* Add Denunciante Modal */}
      <AddPersonModal
        title={"Agregar Denunciante"}
        complainants={complainants}
        setComplainants={setComplainants}
        onHide={() => setShowModal(false)}
        show={showModal}
        setIsCreate={setIsCreate}
      />

      {/* Add InstitutionModal */}
      <AddInstitutionModal
        show={showModalInstitution}
        onHide={() => {
          setShowModalInstitution(false);
        }}
        setIsCreate={setIsCreateInstitution}
        setComplainants={setComplainants}
        complainants={complainants}
      />

      {/* createInstitutionModal */}
      <Modal
        size="lg"
        show={isCreateInstitution}
        onHide={() => {
          setIsCreateInstitution(false);
          setPerson({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar Institucion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InstitucionForm institution_id={person.institution_id} />
        </Modal.Body>
      </Modal>

      <Form validated={validated} onSubmit={handleSubmit}>
        {/* Select the type of complainant */}
        <Row className="mt-2 mb-4">
          <Form.Label className={styles.groupLabel}>
            Tipo de denunciante
          </Form.Label>
          <Form.Group as={Col} controlId="typeValidation">
            {types.map((current) => (
              <Form.Check type="radio">
                <Form.Check.Input
                  type="radio"
                  value={current.value}
                  onChange={changeType}
                  checked={type === current.value}
                  className={styles.checkbox}
                  id={current.value}
                />
                <Form.Check.Label
                  for={current.value}
                  className={styles.checkboxLabel}
                >
                  {current.label}
                </Form.Check.Label>
              </Form.Check>
            ))}
            <Form.Control.Feedback>{`Denuncia ${type} ✓`}</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Debes seleccionar el tipo de la denuncia
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        {/* Add Denunciante button */}
        <Row className="mb-4">
          <Col md={12} style={{ textAlign: "right" }}>
            {type && (
              <Button
                bsPrefix={styles.next}
                onClick={async () => {
                  if (type === "PF") {
                    setShowModal(true);
                  } else {
                    setShowModalInstitution(true);
                  }
                }}
              >
                Agregar Denunciante
              </Button>
            )}
          </Col>
        </Row>

        {complainants && complainants.length > 0 ? (
          <Table className={styles.table} style={{ marginTop: "1rem" }}>
            <thead>
              <th>Nombres y apellidos</th>
              <th>Tipo de documento</th>
              <th>Número de documento</th>
              <th></th>
            </thead>
            <tbody>
              {complainants.map((complainant) => (
                <tr>
                  <td>{`${complainant.firstName ?? ""} ${
                    complainant.lastName ?? ""
                  } ${complainant.name ?? ""}`}</td>
                  <td>
                    {complainant.institution_type ?? complainant.documentType}
                  </td>
                  <td>{complainant.ruc ?? complainant.documentNumber}</td>
                  <td>
                    <IoPersonCircleOutline
                      onClick={(e) => {
                        e.preventDefault();
                        setPerson(complainant);
                        if (!complainant.ruc) {
                          setIsDetail(true);
                        } else {
                          setIsDetailInstitution(true);
                        }
                      }}
                      title="Ver"
                      className={styles.icon}
                    />
                    &nbsp;
                    <AiOutlineFileSearch
                      onClick={(e) => {
                        e.preventDefault();
                        setPerson(complainant);
                        setIsAgent(true);
                      }}
                      title="Representantes"
                      className={styles.icon}
                    />
                    &nbsp;
                    <FiTrash
                      onClick={(e) => removeComplainant(e, complainant)}
                      title="Descartar"
                      className={styles.icon}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <></>
        )}
        {/* ExtraButtons */}
        <Row
          className="mt-4"
          style={{
            display: "flex",
            borderTop: "1px solid silver",
            paddingTop: ".7rem",
          }}
        >
          <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
            <Button bsPrefix={styles.next} onClick={handlePrevious}>
              ANTERIOR
            </Button>
          </Col>
          {complainants.length > 0 ? (
            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
              <Button bsPrefix={styles.next} onClick={handleNext}>
                SIGUIENTE
              </Button>
            </Col>
          ) : (
            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
              <Button bsPrefix={styles.next} onClick={skip}>
                OMITIR
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
};

export default Denunciantes;
