import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

const DireccionesPerson = ({ addresses = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!addresses.length) {
    return <div>No hay direcciones registradas</div>;
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < addresses.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const currentAddress = addresses[currentIndex];

  return (
    <div>
      <h5>Direcciones</h5>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Departamento</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentAddress.department || ""}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Ciudad</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentAddress.city || ""}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Barrio</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentAddress.neighborhood || ""}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Número de casa</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentAddress.house_number || ""}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Calle Principal</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentAddress.principal_street || ""}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Calle Secundaria</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentAddress.secondary_street || ""}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Tipo de Dirección</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentAddress.address_type || ""}
            />
          </Form.Group>
        </Col>
      </Row>

      <p style={{ marginTop: "1rem" }}>
        Mostrando {currentIndex + 1} de {addresses.length}
      </p>
      <div style={{ textAlign: "right" }}>
        <Button
          variant="outline-primary"
          onClick={handlePrev}
          disabled={currentIndex === 0}
          style={{ marginRight: "0.5rem" }}
        >
          Anterior
        </Button>
        <Button
          variant="outline-primary"
          onClick={handleNext}
          disabled={currentIndex === addresses.length - 1}
        >
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default DireccionesPerson;
