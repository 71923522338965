import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

const ContactosPerson = ({ contacts = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!contacts.length) {
    return <div>No hay contactos registrados</div>;
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < contacts.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const currentContact = contacts[currentIndex];

  return (
    <div>
      <h5>Contactos</h5>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Tipo de contacto</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={
                currentContact.contact_type === "EMAIL" ? "Email" : "Teléfono"
              }
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Contacto</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentContact.contact_number || ""}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Especificación</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentContact.especific_type || ""}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Verificado</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentContact.verified ? "Sí" : "No"}
            />
          </Form.Group>
        </Col>
      </Row>

      <p style={{ marginTop: "1rem" }}>
        Mostrando {currentIndex + 1} de {contacts.length}
      </p>
      <div style={{ textAlign: "right" }}>
        <Button
          variant="outline-primary"
          onClick={handlePrev}
          disabled={currentIndex === 0}
          style={{ marginRight: "0.5rem" }}
        >
          Anterior
        </Button>
        <Button
          variant="outline-primary"
          onClick={handleNext}
          disabled={currentIndex === contacts.length - 1}
        >
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default ContactosPerson;
