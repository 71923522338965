import React from "react";
import { Button, Form, Col, Row, Table, Badge } from "react-bootstrap";
import styles from "./Steps.module.scss";
import { checkRoles } from "../../../../Keycloak";
import { useKeycloak } from "@react-keycloak/web";

const Step2 = ({ next, prev, data }) => {
  const { keycloak } = useKeycloak();

  return (
    <>
      <Form>
        <Form.Group className={styles.group}>
          <Form.Label className={styles.groupLabel}>
            Unidades Fiscales
          </Form.Label>
          {data.prosecutor_divisions && data.prosecutor_divisions.length > 0 ? (
            <>
              <Table className={styles.table} style={{ marginTop: "0.2rem" }}>
                <thead>
                  <th>Nombre de la Unidad Fiscal</th>
                  <th>Fiscal</th>
                </thead>
                <tbody>
                  {data.prosecutor_divisions.map((division, index) => (
                    <tr key={index}>
                      <td>{division.prosecutor_division_name}</td>
                      <td>{division.prosecutor_name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <Row>
              <Form.Label className={styles.noContent}>
                No hay elementos
              </Form.Label>
            </Row>
          )}
        </Form.Group>
      </Form>
    </>
  );
};

export default Step2;
