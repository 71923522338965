import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Col, Row} from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./DnitContext.module.css";
import { useKeycloak } from "@react-keycloak/web";
import { useParams } from "react-router-dom";
import Information from "./Information/Information";

const CgrContext = () => {
  const { keycloak } = useKeycloak();

  let { id } = useParams();

  const [dnit, setDnit] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState("information");

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  const finddnit = () => {
    setLoading(true);
    const URL = `${process.env.REACT_APP_API_URL}/dnit/reports/${id}`;
    axios
      .get(URL, prepareHeaders())
      .then((res) => {
        setDnit(res.status === 200 ? res.data : undefined);
        setLoading(false);
      })
      .catch((err) => {
        setDnit(undefined);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) finddnit();
  }, [id]);

  useEffect(() => {
    if (id) finddnit();
  }, []);

  return dnit ? (
    <>
      <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Col md={2}>
          <div className={styles.sideBar}>
            <Form className={styles.filterContainer}>
              <Row>
                <Col style={{ textAlign: "right" }}>
                  <Form.Label
                    className={styles.id}
                  >{`Nº ${dnit.dnit_report_id}`}</Form.Label>
                </Col>
              </Row>
            </Form>
            <Link
              className={`${styles.button} ${
                menu === "information" ? styles.active : ""
              }`}
              onClick={(e) => {
                setMenu("information");
              }}
            >
              <span>Información</span>
            </Link>
            {/*<Link
              className={`${styles.button} ${
                menu === "team" ? styles.active : ""
              }`}
              onClick={(e) => {
                setMenu("team");
              }}
            >
              <span>Unidad fiscal</span>
            </Link>
            <Link
              className={`${styles.button} ${
                menu === "history" ? styles.active : ""
              }`}
              onClick={(e) => {
                setMenu("history");
              }}
            >
              <span>Historial</span>
            </Link>
            <Link
              className={`${styles.button} ${
                menu === "disassociate" ? styles.active : ""
              }`}
              onClick={(e) => {
                setMenu("disassociate");
              }}
            >
              <span>Inhibición</span>
            </Link>*/}
          </div>
        </Col>
        <Col md={10}>
          <div className={styles.container}>
            {menu === "information" ? (
              <Information
                dnit={dnit}
                setDnit={setDnit}
                callback={() => finddnit()}
              />
            ) : (
              <></>
            )}
            {/*{menu === "team" ? (
              <UnidadFiscal person={person} callback={() => findperson()} />
            ) : (
              <></>
            )}
            {menu === "history" ? (
              <History person={person} callback={() => findperson()} />
            ) : (
              <></>
            )}
            {menu === "disassociate" ? (
              <Disassociate person={person} callback={() => findperson()} />
            ) : (
              <></>
            )}*/}
          </div>
        </Col>
      </Row>
    </>
  ) : (
    <></>
  );
};

export default CgrContext;
