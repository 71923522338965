import React, { useState} from "react";
import { Form, Row, Col, Button} from "react-bootstrap";
import { FiPlus} from "react-icons/fi";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import styles from "./Information.module.css";
import { Toaster } from "react-hot-toast";
import TaxpayerModal from "../Edit/TaxpayerModal/TaxpayerModal";
import DnitModal from "../Edit/DnitModal/DnitModal";
import ResolutionFgeModal from "../Edit/ResolutionFgeModal/ResolutionFgeModal";
import ResolutionUdeaModal from "../Edit/ResolutionUdeaModal/ResolutionUdeaModal";
import DivisionModal from "../Edit/DivisionModal/DivisionModal";
import CrimesModal from "../Edit/CrimesModal/CrimesModal";

const Information = ({ dnit, setDnit, callback }) => {
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();

  const [isAddMode, setIsAddMode] = useState(false);

  const [dnitModal, setDnitModal] = useState(false);
  const [taxpayerModal, setTaxpayerModal] = useState(false);
  const [resolutionFgeModal, setResolutionFgeModal] = useState(false);
  const [resolutionUdeaModal, setResolutionUdeaModal] = useState(false);
  const [prosecutorsDivisionsEdit, setProsecutorsDivisionsEdit] = useState(false);
  const [crimesModal, setCrimesModal] = useState(false);

  const [receptionEdit, setReceptionEdit] = useState(false);
  const [divisionModal, setDivisionModal] = useState(false);

  const [currentDivisionIndex, setCurrentDivisionIndex] = useState(0);
  const [currentCrimeIndex, setCurrentCrimeIndex] = useState(0);
  
    const handleNextDivisions = () => {
      if (currentDivisionIndex < dnit.prosecutors_divisions.length - 1) {
        setCurrentDivisionIndex(currentDivisionIndex + 1);
      }
    };
  const handlePreviousDivisions = () => {
      if (currentDivisionIndex > 0) {
        setCurrentDivisionIndex(currentDivisionIndex - 1);
      }
    };
  
    const handleNextCrimes = () => {
      if (currentCrimeIndex < dnit.prosecutors_divisions.length - 1) {
        setCurrentCrimeIndex(currentCrimeIndex + 1);
      }
    };
  const handlePreviousCrimes = () => {
      if (currentCrimeIndex > 0) {
        setCurrentCrimeIndex(currentCrimeIndex - 1);
      }
    };
  
    const currentDivision =
    dnit.prosecutors_divisions[currentDivisionIndex] === undefined
      ? {}
      : dnit.prosecutors_divisions[currentDivisionIndex];
  
    const currentCrime =
    dnit.crimes[currentCrimeIndex] === undefined
      ? {}
      : dnit.crimes[currentCrimeIndex];
  

    
  const prepareHeaders = () => {
    return {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  return (
    <>
      <Toaster />
      <DnitModal
        data={dnit}
        show={dnitModal}
        handleClose={() => setDnitModal(false)}
        callback={() => {
          setDnitModal(false);
          callback();
        }}
      />
      <TaxpayerModal
        data={dnit}
        show={taxpayerModal}
        handleClose={() => setTaxpayerModal(false)}
        callback={() => {
          setTaxpayerModal(false);
          callback();
        }}
      />
      <ResolutionFgeModal
        data={dnit}
        show={resolutionFgeModal}
        handleClose={() => setResolutionFgeModal(false)}
        callback={() => {
          setResolutionFgeModal(false);
          callback();
        }}
      />
      <ResolutionUdeaModal
        data={dnit}
        show={resolutionUdeaModal}
        handleClose={() => setResolutionUdeaModal(false)}
        callback={() => {
          setResolutionUdeaModal(false);
          callback();
        }}
      />
      <DivisionModal
        data={dnit}
        show={divisionModal}
        add={isAddMode}
        handleClose={() => {
          callback();
          setDivisionModal(false);
          setIsAddMode(false);
        }}
        currentDivisionIndex={currentDivisionIndex}
      />
      <CrimesModal
        data={dnit}
        show={crimesModal}
        add={isAddMode}
        handleClose={() => {
          callback();
          setCrimesModal(false);
          setIsAddMode(false);
        }}
        currentCrimeIndex={currentCrimeIndex}
      />
      <Form>
        <Row
          className={styles.group}
          onClick={(e) => {
            e.preventDefault();
            setDnitModal(true);
            }}
            >
            <Form.Label className={styles.groupLabel}>DNIT</Form.Label>
            <Row className="mb-2">
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
              N° de Reporte
              </Form.Label>
              <Form.Control
              bsPrefix={styles.input}
              value={dnit.report_dnit_number}
              disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
              Fecha de reporte
              </Form.Label>
              <Form.Control
              bsPrefix={styles.input}
              value={dnit.report_dnit_date}
              disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
              Persona Expuesta Políticamente
              </Form.Label>
              <Form.Control
              bsPrefix={styles.input}
              value={dnit.pep ? "Si" : "No"}
              disabled
              />
            </Col>
            </Row>
            <Row>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
              Funcionario Público
              </Form.Label>
              <Form.Control
              bsPrefix={styles.input}
              value={dnit.public_official ? "Si" : "No"}
              disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
              Fecha de elaboración
              </Form.Label>
              <Form.Control
              bsPrefix={styles.input}
              value={dnit.elaboration_date}
              disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
              Recibido
              </Form.Label>
              <Form.Control
              bsPrefix={styles.input}
              value={dnit.received_at}
              disabled
              />
            </Col>
            
            </Row>
            <Row>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
              Tipo de fiscalización
              </Form.Label>
              <Form.Control
              bsPrefix={styles.input}
              value={dnit.fiscalization_type}
              disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
              Fiscalización notificada el
              </Form.Label>
              <Form.Control
              bsPrefix={styles.input}
              value={dnit.notification_fiscalization_date}
              disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
              Número de fiscalización
              </Form.Label>
              <Form.Control
              bsPrefix={styles.input}
              value={dnit.fiscalization_number}
              disabled
              />
            </Col>
            </Row>
            </Row>
            <Row className={styles.group}>
            <Row
            onClick={(e) => {
              e.preventDefault();
              setTaxpayerModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Contribuyente
                </Form.Label>
              </Col>
            </Row>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      RUC
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={dnit.tax_payer.ruc}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Nombre
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={dnit.tax_payer.name}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Dirección Fiscal
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={dnit.tax_payer.fiscal_address}
                      disabled
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Tipo de contribuyente
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={dnit.tax_payer.taxpayer_type === "PERSON" ? "Persona" : dnit.tax_payer.taxpayer_type === "INSTITUTION" ? "Institución" : ""}
                      disabled
                    />
                  </Col>
                </Row>
          </Row>
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setResolutionFgeModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Resolución FGE
                </Form.Label>
              </Col>
            </Row>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Número de Resolución
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={dnit.resolution_fge.resolution_fge_number}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Fecha de resolución
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={dnit.resolution_fge.resolution_fge_date}
                      disabled
                    />
                  </Col>
                </Row>
          </Row>
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setResolutionUdeaModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Resolución UDEA
                </Form.Label>
              </Col>
            </Row>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Número de Resolución
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={dnit.resolution_udea.resolution_udea_number}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Fecha de Resolución
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={dnit.resolution_udea.resolution_udea_date}
                      disabled
                    />
                  </Col>
                </Row>
          </Row>
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setDivisionModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Unidad fiscal{" "}
                  {dnit.prosecutors_divisions.length > 0 && (
                    <>
                      {" "}
                      {currentDivisionIndex + 1} de {dnit.prosecutors_divisions.length}
                    </>
                  )}
                </Form.Label>
              </Col>
            </Row>
            {dnit.prosecutors_divisions.length > 0 && (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Nombre
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentDivision.name}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Fiscal
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentDivision.prosecutor.firstName + " " + currentDivision.prosecutor.lastName}
                      disabled
                    />
                  </Col>
                </Row>
              </>
            )}
          </Row>
          {dnit.prosecutors_divisions.length > 1 && (
            <Row style={{ marginTop: "1rem" }}>
              <Col xs="auto">
                <Button
                  className="boton-over"
                  variant="secondary"
                  onClick={handlePreviousDivisions}
                  disabled={currentDivisionIndex === 0}
                >
                  {"<"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  className="boton-over"
                  variant="secondary"
                  onClick={handleNextDivisions}
                  disabled={currentDivisionIndex === dnit.prosecutors_divisions.length - 1}
                >
                  {">"}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setCrimesModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Hecho Punible{" "}
                  {dnit.crimes.length > 0 && (
                    <>
                      {" "}
                      {currentCrimeIndex + 1} de {dnit.crimes.length}
                    </>
                  )}
                </Form.Label>
              </Col>
            </Row>
            {dnit.crimes.length > 0 && (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Número
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentCrime.crime_id}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Fecha posible
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentCrime.possible_date}
                      disabled
                    />
                  </Col>
                </Row>
              </>
            )}
          </Row>
          {dnit.crimes.length > 1 && (
            <Row style={{ marginTop: "1rem" }}>
              <Col xs="auto">
                <Button
                  className="boton-over"
                  variant="secondary"
                  onClick={handlePreviousCrimes}
                  disabled={currentCrimeIndex === 0}
                >
                  {"<"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  className="boton-over"
                  variant="secondary"
                  onClick={handleNextCrimes}
                  disabled={currentCrimeIndex === dnit.crimes.length - 1}
                >
                  {">"}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Form>
    </>
  );
};

export default Information;
