import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const DatosGeneralesInstitution = ({ institutionData }) => {
  if (!institutionData) {
    return <div>Cargando datos generales...</div>;
  }
  console.log(institutionData);

  const {
    institution_type,
    ruc,
    name
  } = institutionData;

  return (
    <div>
      <h5>Datos Generales</h5>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Nombres</Form.Label>
            <Form.Control type="text" readOnly value={name || ""} />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Número de RUC</Form.Label>
            <Form.Control type="text" readOnly value={ruc || ""} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Tipo de Empresa</Form.Label>
            <Form.Control type="text" readOnly value={institution_type || ""} />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default DatosGeneralesInstitution;
