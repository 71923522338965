import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Table } from "react-bootstrap";
import styles from "./Steps.module.scss";
import { GrFormView, GrFormTrash, GrFormEdit } from "react-icons/gr";
import DatosGeneralesModal from "../../DenunciasContext/Edit/DatosGeneralesModal/DatosGeneralesModal.js";
import PersonModal from "../../../../../components/modals/PersonModal/PersonModal.jsx";

const Step1 = ({
  next,
  data,
  denunciante,
  denunciado,
  testigo,
  setDenunciante,
  setDenunciado,
  setTestigo,
  currentComplainantIndex,
  currentDefendantIndex,
  currentWitnessIndex,
}) => {
  const [datosGeneralesEdit, setDatosGeneralesEdit] = useState(false);
  const [person, setPerson] = useState(null);
  const [personId, setPersonId] = useState(undefined);
  const [firstName, setFirstName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [documentNumber, setDocumentNumber] = useState(undefined);
  const [documentType, setDocumentType] = useState(undefined);
  const [birthDate, setBirthDate] = useState(undefined);
  const [selectedPersonId, setSelectedPersonId] = useState(undefined);
  const [showSelectedPerson, setShowSelectedPerson] = useState(false);

  useEffect(() => {
    if (data && (denunciante || denunciado || testigo)) {
      let selectedPerson = null;

      if (denunciante) {
        selectedPerson = data.complainants[currentComplainantIndex] || null;
      } else if (denunciado) {
        selectedPerson = data.defendants[currentDefendantIndex] || null;
      } else if (testigo) {
        selectedPerson = data.witnesses[currentWitnessIndex] || null;
      }

      if (selectedPerson) {
        setPerson(selectedPerson);
      }
    }
  }, [
    data,
    denunciante,
    denunciado,
    testigo,
    setDenunciante,
    setDenunciado,
    setTestigo,
    currentComplainantIndex,
    currentDefendantIndex,
    currentWitnessIndex,
  ]);

  useEffect(() => {
    if (person) {
      setDocumentType(person.document_type || "");
      setDocumentNumber(person.document_number || "");
      setPersonId(person.personId || "");
      setFirstName(person.first_name || "");
      setLastName(person.last_name || "");
      setBirthDate(person.birth_date || "");
    }
  }, [person]);

  const handleShowMore = (personId) => {
    setSelectedPersonId(personId);
    setShowSelectedPerson(true);
  };

  return data ? (
    <>
      <PersonModal
        personId={selectedPersonId}
        show={showSelectedPerson}
        onHide={() => setShowSelectedPerson(false)}
      />
      <DatosGeneralesModal
        data={data}
        person={person}
        denunciado={denunciado}
        denunciante={denunciante}
        testigo={testigo}
        show={datosGeneralesEdit}
        handleClose={() => {
          setDatosGeneralesEdit(false);
          setDenunciante(false);
          setDenunciado(false);
          setTestigo(false);
        }}
        callback={() => {
          setDatosGeneralesEdit(false);
          setDenunciante(false);
          setDenunciado(false);
          setTestigo(false);
        }}
      />
      <Form>
        <Form.Group className={styles.group}>
          <Form.Label className={styles.groupLabel}>
            Datos personales
          </Form.Label>
          <Row style={{ marginTop: "0.2rem" }}>
            <Col md={2}>
              <Form.Label as={Col} bsPrefix={styles.label}>
                Nombres
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                type="text"
                value={firstName}
                disabled
              />
            </Col>
            <Col md={2}>
              <Form.Label as={Col} bsPrefix={styles.label}>
                Apellidos
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                type="text"
                value={lastName}
                disabled
              />
            </Col>
            <Col md={2} className="me-3">
              <Form.Label as={Col} bsPrefix={styles.label}>
                Fecha de nacimiento
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                type="text"
                value={birthDate}
                disabled
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col md={3}>
              <Form.Label as={Col} bsPrefix={styles.label}>
                Tipo de documento
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                type="text"
                value={documentType}
                disabled
              />
            </Col>
            <Col>
              <Form.Label as={Col} bsPrefix={styles.label}>
                Número de documento
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                type="text"
                value={documentNumber}
                disabled
              />
            </Col>
          </Row>
        </Form.Group>
      </Form>
      {/*<Row>
        <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
          <Button
            className={styles.bordeauxButton}
            onClick={(e) => {
              setDatosGeneralesEdit(true);
            }}
          >
            Ver
          </Button>
        </Col>
      </Row>*/}
      <Row>
        <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
          <Button
            className={styles.bordeauxButton}
            onClick={(e) => {
              handleShowMore(personId);
            }}
          >
            Ver más
          </Button>
        </Col>
      </Row>
    </>
  ) : (
    <></>
  );
};

export default Step1;
