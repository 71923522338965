import React, { useState, useEffect } from "react";
import { Row, Col, Form, Badge, Button } from "react-bootstrap";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import styles from "./ReceptionProsecutorDivisions.module.css";
import { BsNewspaper, BsFillPeopleFill } from "react-icons/bs";
import { FaMapMarkedAlt } from "react-icons/fa";
import { SlOptions } from "react-icons/sl";
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineFileSearch } from "react-icons/ai";
import { TbMinusVertical, TbListCheck } from "react-icons/tb";
import { AiOutlineFileDone } from "react-icons/ai";
import { IoDocumentsOutline } from "react-icons/io5";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

const ReceptionProsecutorDivisions = ( { data, callback } ) => {

    const [ step, setStep ] = useState(1);
    const [ showEditDate, setShowEditDate ] = useState(false);
    const [ showJudgmentModal, setShowJudgmentModal ] = useState(false);
    const [ current, setCurrent ] = useState(undefined);

    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }    

    const prepareHeadersString = () => {
        return {
            headers: {
                "Content-Type": "text/plain",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }        


    {/*const judge = (e, data) => {
        e.preventDefault();
        setCurrent(data);
        setShowJudgmentModal(true);
    }*/}
 
    return (
        <div className={styles.container}>
            <Row>
                <Col md={8}>
                    <Form.Label className={styles.title}>{`Nº ${data.reception_id}`}</Form.Label>
                </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <Col md="auto">
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <BsNewspaper className={`${styles.navIcon} ${step === 1 ? styles.active : ""}`} onClick={ (e) => { setStep(1) }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbMinusVertical className={`${styles.navIcon}`}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <TbListCheck className={`${styles.navIcon} ${step === 2 ? styles.active : ""}`} onClick={ (e) => { setStep(2) }}/>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    {
                        step === 1 ? <Step1 data={data} /> : (
                            step === 2 ? <Step2 data={data}/> : <></>
                        )
                    }
                </Col>
            </Row>
            <Row style={{ display: "flex" }}>
                {
                    step === 1 ? (
                        <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                            <Button bsPrefix={styles.next} onClick={(e) => { setStep(step+1) }}>SIGUIENTE</Button>
                        </Col>
                    )
                    : (
                        <Col md={12} style={{ marginRight: "auto", textAlign: "left" }}>
                            <Button bsPrefix={styles.next} onClick={(e) => { setStep(step-1) }}>ANTERIOR</Button>
                        </Col>
                    )
                }
            </Row>
        </div>
    )

}

export default ReceptionProsecutorDivisions;