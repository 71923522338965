import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineSearch, AiOutlineClear } from "react-icons/ai";
import {
  Form,
  Button,
  Col,
  Row,
  Table,
  InputGroup,
  DropdownButton,
  Dropdown,
  Badge,
} from "react-bootstrap";
import {
  RiTableFill,
  RiArticleFill,
  RiSortAsc,
  RiSortDesc,
  RiCloseFill,
} from "react-icons/ri";
import { FaFileCirclePlus } from "react-icons/fa6";
import styles from "./DNITConsulta.module.css";
import CustomPagination from "../../components/pagination/CustomPagination";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import ContraloriaCard from "../../components/ContraloriaForm/ReportCard";
import ReportCard from "../../components/ContraloriaForm/ReportCard";
import CrimesModal from "../../components/modals/CrimesModal";

const DNITConsulta = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(2);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);

  // Filtros
  const [resolutionDnitNumber, setResolutionDnitNumber] = useState("");
  const [resolutionFgeNumber, setResolutionFgeNumber] = useState("");
  const [resolutionUdeaNumber, setResolutionUdeaNumber] = useState("");
  const [fiscalizationType, setFiscalizationType] = useState("");
  const [dnitDateAfter, setDnitDateAfter] = useState("");
  const [dnitDateBefore, setDnitDateBefore] = useState("");
  const [fgeDateAfter, setFgeDateAfter] = useState("");
  const [fgeDateBefore, setFgeDateBefore] = useState("");
  const [udeaDateAfter, setUdeaDateAfter] = useState("");
  const [udeaDateBefore, setUdeaDateBefore] = useState("");
  const [receivedAfter, setReceivedAfter] = useState("");
  const [receivedBefore, setReceivedBefore] = useState("");
  const [publicOfficial, setPublicOfficial] = useState("TODOS");
  const [pep, setPep] = useState("TODOS");
  const [fiscalizationNumber, setFiscalizationNumber] = useState("");
  const [showCrimeModal, setShowCrimeModal] = useState(false);
  const [selectedCrimes, setSelectedCrimes] = useState([]);

  const [sorting, setSorting] = useState([]);

  const [view, setView] = useState("C"); 

  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const prepareHeaders = () => {
    return {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${keycloak.token}`,
        channel: "1",
      },
    };
  };

  const resetFilters = (e) => {
    e.preventDefault();
    setResolutionDnitNumber("");
    setResolutionFgeNumber("");
    setResolutionUdeaNumber("");
    setFiscalizationType("");
    setDnitDateAfter("");
    setDnitDateBefore("");
    setFgeDateAfter("");
    setFgeDateBefore("");
    setUdeaDateAfter("");
    setUdeaDateBefore("");
    setReceivedAfter("");
    setReceivedBefore("");
    setPublicOfficial("TODOS");
    setPep("TODOS");
    setFiscalizationNumber("");
    setSorting([]);
    setPage(1);
  };

  const booleanParam = (val) => {
    if (val === "SI") return true;
    if (val === "NO") return false;
    return undefined;
  };

  const buildParams = () => {
    let query = `?page=${page}&page_size=${pageSize}`;
    if (resolutionDnitNumber) {
      query += `&resolution_dnit_number=${resolutionDnitNumber}`;
    }
    if (resolutionFgeNumber) {
      query += `&resolution_fge_number=${resolutionFgeNumber}`;
    }
    if (resolutionUdeaNumber) {
      query += `&resolution_udea_number=${resolutionUdeaNumber}`;
    }
    if (fiscalizationType) {
      query += `&fiscalization_type=${fiscalizationType}`;
    }
    if (dnitDateAfter) {
      query += `&dnit_date_after=${dnitDateAfter}`;
    }
    if (dnitDateBefore) {
      query += `&dnit_date_before=${dnitDateBefore}`;
    }
    if (fgeDateAfter) {
      query += `&fge_date_after=${fgeDateAfter}`;
    }
    if (fgeDateBefore) {
      query += `&fge_date_before=${fgeDateBefore}`;
    }
    if (udeaDateAfter) {
      query += `&udea_date_after=${udeaDateAfter}`;
    }
    if (udeaDateBefore) {
      query += `&udea_date_before=${udeaDateBefore}`;
    }
    if (receivedAfter) {
      query += `&received_after=${receivedAfter}`;
    }
    if (receivedBefore) {
      query += `&received_before=${receivedBefore}`;
    }

    const poVal = booleanParam(publicOfficial);
    if (poVal !== undefined) {
      query += `&public_official=${poVal}`;
    }

    const pepVal = booleanParam(pep);
    if (pepVal !== undefined) {
      query += `&pep=${pepVal}`;
    }

    if (fiscalizationNumber) {
      query += `&fiscalization_number=${fiscalizationNumber}`;
    }

    // Para sorting agregamos una línea por cada elemento
    // Ejemplo: &sort_by=dnit_date:ASC
    sorting.forEach((s) => {
      query += `&sort_by=${s.key}:${s.direction}`;
    });

    return query;
  };

  const findReports = () => {
    setLoading(true);
    const URL = process.env.REACT_APP_API_URL + "/dnit/reports" + buildParams();
    axios
      .get(URL, prepareHeaders())
      .then((res) => {
        console.log(res.data);

        setReports(res.data.content || []);
        setTotalPages(res.data.totalPages || 1);
        setTotalElements(res.data.totalElements || 0);
        setLoading(false);
      })
      .catch((err) => {
        setReports([]);
        setLoading(false);
      });
  };

  const sortingBy = (field, label) => {
    // Si el campo ya existe en sorting, lo removemos
    const exists = sorting.find((s) => s.key === field);
    if (exists) {
      setSorting(sorting.filter((s) => s.key !== field));
    } else {
      // Agregamos con dirección ASC por defecto
      setSorting([...sorting, { key: field, label, direction: "ASC" }]);
    }
  };

  const changeDirection = (sortItem) => {
    let elements = sorting.map((s) => {
      if (s.key === sortItem.key) {
        return { ...s, direction: s.direction === "ASC" ? "DESC" : "ASC" };
      }
      return s;
    });
    setSorting(elements);
  };

  const handleCloseCrimeModal = (e) => {

    setShowCrimeModal(false);
}

  useEffect(() => {
    findReports();
  }, [page, pageSize, sorting]);

  return (
    <>
      <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Col md={3}>
          <div className={styles.sideBar}>
            <Form>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder="Resol. DNIT N°"
                      value={resolutionDnitNumber}
                      className={styles.inputFloating}
                      onChange={(e) => setResolutionDnitNumber(e.target.value)}
                    />
                    <label className={styles.label}>Resol. DNIT N°</label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder="Resol. FGE N°"
                      value={resolutionFgeNumber}
                      className={styles.inputFloating}
                      onChange={(e) => setResolutionFgeNumber(e.target.value)}
                    />
                    <label className={styles.label}>Resol. FGE N°</label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder="Resol. UDEA N°"
                      value={resolutionUdeaNumber}
                      className={styles.inputFloating}
                      onChange={(e) => setResolutionUdeaNumber(e.target.value)}
                    />
                    <label className={styles.label}>Resol. UDEA N°</label>
                  </Form.Floating>
                </Form.Group>
              </Row>

              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col} md={6}>
                  <Form.Floating>
                    <Form.Control
                      type="date"
                      value={dnitDateAfter}
                      className={styles.input}
                      onChange={(e) => setDnitDateAfter(e.target.value)}
                    />
                    <label className={styles.label}>Desde DNIT</label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Floating>
                    <Form.Control
                      type="date"
                      value={dnitDateBefore}
                      className={styles.input}
                      onChange={(e) => setDnitDateBefore(e.target.value)}
                    />
                    <label className={styles.label}>Hasta DNIT</label>
                  </Form.Floating>
                </Form.Group>
              </Row>

              <Row style={{ marginBottom: ".5rem" }}>

                <Form.Group as={Col} md={6}>
                  <Form.Floating>
                    <Form.Control
                      type="date"
                      value={fgeDateAfter}
                      className={styles.input}
                      onChange={(e) => setFgeDateAfter(e.target.value)}
                    />
                    <label className={styles.label}>Desde FGE</label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Floating>
                    <Form.Control
                      type="date"
                      value={fgeDateBefore}
                      className={styles.input}
                      onChange={(e) => setFgeDateBefore(e.target.value)}
                    />
                    <label className={styles.label}>Hasta FGE</label>
                  </Form.Floating>
                </Form.Group>
              </Row>

              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col} md={6}>
                  <Form.Floating>
                    <Form.Control
                      type="date"
                      value={udeaDateAfter}
                      className={styles.input}
                      onChange={(e) => setUdeaDateAfter(e.target.value)}
                    />
                    <label className={styles.label}>Desde UDEA</label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Floating>
                    <Form.Control
                      type="date"
                      value={udeaDateBefore}
                      className={styles.input}
                      onChange={(e) => setUdeaDateBefore(e.target.value)}
                    />
                    <label className={styles.label}>Hasta UDEA</label>
                  </Form.Floating>
                </Form.Group>
              </Row>

              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col} md={6}>
                  <Form.Floating>
                    <Form.Control
                      type="datetime-local"
                      value={receivedAfter}
                      className={styles.input}
                      onChange={(e) => setReceivedAfter(e.target.value)}
                    />
                    <label className={styles.label}>Desde recepción</label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Floating>
                    <Form.Control
                      type="datetime-local"
                      value={receivedBefore}
                      className={styles.input}
                      onChange={(e) => setReceivedBefore(e.target.value)}
                    />
                    <label className={styles.label}>Hasta recepción</label>
                  </Form.Floating>
                </Form.Group>
              </Row>

              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Select
                      className={styles.inputFloating}
                      value={publicOfficial}
                      onChange={(e) => setPublicOfficial(e.target.value)}
                    >
                      <option value="TODOS">TODOS</option>
                      <option value="SI">SI</option>
                      <option value="NO">NO</option>
                    </Form.Select>
                    <label className={styles.label}>Funcionario público</label>
                  </Form.Floating>
                </Form.Group>
              </Row>


              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Select
                      className={styles.inputFloating}
                      value={pep}
                      onChange={(e) => setPep(e.target.value)}
                    >
                      <option value="TODOS">TODOS</option>
                      <option value="SI">SI</option>
                      <option value="NO">NO</option>
                    </Form.Select>
                    <label className={styles.label}>PEP</label>
                  </Form.Floating>
                </Form.Group>
              </Row>

              <Row style={{ marginBottom: ".5rem" }}>
                            <Form.Group as={Col} onClick={()=>setShowCrimeModal(true)}>
                                <Form.Floating>
                                    <Form.Select
                                    id="floatingCrime"
                                    className={styles.inputFloating}
                                    
                                    >
                                    </Form.Select>
                                    <label htmlFor="floatingCrime" className={styles.label}>
                                    Hechos Punibles
                                    </label>
                                </Form.Floating>
                                </Form.Group>
                            <CrimesModal showModal={showCrimeModal} handleClose={handleCloseCrimeModal} updateSelectedCrimes={setSelectedCrimes}/>
                           
              </Row>

              <Row style={{ marginBottom: "1rem" }}>
                <Col
                  style={{
                    textAlign: "right",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <DropdownButton
                    bsPrefix={styles.secondary}
                    title="Ordenar"
                    style={{ marginLeft: "auto" }}
                  >
                    <Dropdown.Item
                      onClick={(e) => sortingBy("dnit_date", "Fecha DNIT")}
                      className={styles.item}
                    >
                      Fecha DNIT
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => sortingBy("fge_date", "Fecha FGE")}
                      className={styles.item}
                    >
                      Fecha FGE
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => sortingBy("udea_date", "Fecha UDEA")}
                      className={styles.item}
                    >
                      Fecha UDEA
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) =>
                        sortingBy("received_at", "Fecha recepción")
                      }
                      className={styles.item}
                    >
                      Fecha recepción
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) =>
                        sortingBy("fiscalization_type", "Tipo fiscalización")
                      }
                      className={styles.item}
                    >
                      Tipo fiscalización
                    </Dropdown.Item>
                  </DropdownButton>
                  &nbsp;&nbsp;
                  <Button onClick={resetFilters} bsPrefix={styles.primary}>
                    Limpiar <AiOutlineClear />
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      setPage(1);
                      findReports();
                    }}
                    bsPrefix={styles.primary}
                  >
                    Buscar <AiOutlineSearch />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>        
        </Col>
        <Col md={9}>
          <div className={styles.container}>
            <h2>Reportes DNIT</h2>
            <Row>
              <Col>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dnit/registrar");
                  }}
                  bsPrefix={styles.newPrimary}
                  style={{ marginLeft: "auto" }}
                >
                  &nbsp;&nbsp;Nuevo reporte &nbsp;&nbsp;
                  <FaFileCirclePlus />
                  &nbsp;&nbsp;
                </Button>
              </Col>
            </Row>
            <Row style={{ flexDirection: "row-reverse" }}>
              <Col md="auto" style={{ textAlign: "right" }}>
                <InputGroup
                  style={{
                    flexWrap: "wrap",
                    alignItems: "stretch",
                    justifyContent: "flex-end",
                  }}
                >
                  <RiTableFill
                    style={{ marginTop: "auto" }}
                    className={
                      view === "T" ? styles.iconActive : styles.iconInactive
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setView("T");
                      setPageSize(20);
                      setPage(1);
                    }}
                  />
                  &nbsp;
                  <RiArticleFill
                    style={{ marginTop: "auto" }}
                    className={
                      view === "C" ? styles.iconActive : styles.iconInactive
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setView("C");
                      setPageSize(2);
                      setPage(1);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col md="auto" style={{ textAlign: "right" }}>
                {sorting.map((sort) => (
                  <Badge className={styles.sortItem} key={sort.key}>
                    {sort.label}
                    &nbsp;
                    {sort.direction === "ASC" ? (
                      <RiSortAsc
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          changeDirection(sort);
                        }}
                      />
                    ) : (
                      <RiSortDesc
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          changeDirection(sort);
                        }}
                      />
                    )}
                    &nbsp;
                    <RiCloseFill
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        sortingBy(sort.key, sort.label);
                      }}
                    />
                  </Badge>
                ))}
              </Col>
            </Row>
            <Row style={{ marginTop: ".5rem", marginBottom: "1.5rem" }}>
              <Col md={6}>
                <CustomPagination
                  page={page}
                  pageSize={pageSize}
                  changePageSize={setPageSize}
                  toPage={(p) => setPage(p)}
                  totalPages={totalPages}
                />
              </Col>
              <Col md={6} style={{ textAlign: "right", marginTop: "auto" }}>
                <span>
                  {totalElements} {totalElements > 1 ? "reportes" : "reporte"}
                </span>
              </Col>
            </Row>
            {loading ? (
              <span className="mt-3 mb-2">Buscando...</span>
            ) : !loading && (!reports || reports.length === 0) ? (
              <span className="mt-3 mb-2">No hay resultados</span>
            ) : (
              <>
                {view === "T" ? (
                  <Table className={styles.table}>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Fecha DNIT</th>
                        <th>Fecha FGE</th>
                        <th>Fecha UDEA</th>
                        <th>Fecha recepción</th>
                        <th>Resol. DNIT</th>
                        <th>Resol. FGE</th>
                        <th>Resol. UDEA</th>
                        <th>Tipo Fiscalización</th>
                        <th>PEP</th>
                        <th>Funcionario público</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports.map((report) => (
                        <tr key={report.dnit_report_id}>
                          <td>{report.dnit_report_id}</td>
                          <td>
                            {report.resolution_dnit?.resolution_dnit_date ||
                              "-"}
                          </td>
                          <td>
                            {report.resolution_fge?.resolution_fge_date || "-"}
                          </td>
                          <td>
                            {report.resolution_udea?.resolution_udea_date ||
                              "-"}
                          </td>
                          <td>{report.received_at || "-"}</td>
                          <td>
                            {report.resolution_dnit?.resolution_dnit_number ||
                              "-"}
                          </td>
                          <td>
                            {report.resolution_fge?.resolution_fge_number ||
                              "-"}
                          </td>
                          <td>
                            {report.resolution_udea?.resolution_udea_number ||
                              "-"}
                          </td>
                          <td>{report.fiscalization_type || "-"}</td>
                          <td>{report.pep ? "Sí" : "No"}</td>
                          <td>{report.public_official ? "Sí" : "No"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  reports.map((report) => (

                        <ReportCard
                        ReportId={report.dnit_report_id}
                        cgrDate={report.report_dnit_date || "-"}
                        cgrNro={report.report_dnit_number || "-"}
                        fgeDate={report.resolution_fge?.resolution_fge_date || "-"}
                        fgeNro={report.resolution_fge?.resolution_fge_number || "-"}
                        udeaDate={report.resolution_udea?.resolution_udea_date || "-"}
                        udeaNro={report.resolution_udea?.resolution_udea_number || "-"}
                        ReceivedAt={report.received_at || "-"}
                        FiscalizationType={report.fiscalization_type || "-"}
                        Pep={report.pep ? "Sí" : "No"}
                        PublicOfficial={report.public_official ? "Sí" : "No"}
                        amount={report.amount || "-"}
                        institution={report.tax_payer}
                        isDnit={true}
                      />
                    
                  ))
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DNITConsulta;
