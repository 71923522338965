import React, { useState } from "react";
import { Button, Form, Col, Row, Table } from "react-bootstrap";
import styles from "./Steps.module.scss"

const Step1 = ( { next, data } ) => {

    const [ showComplainant, setShowComplainant ] = useState(false);
    const [ currentComplainant, setCurrentComplainant ] = useState(undefined);

    const [ showReception, setShowReception ] = useState(false);
    const [ currentReception, setCurrentReception ] = useState(undefined);

    return (
        <>
            {/*<ComplainantModal data={currentComplainant} show={showComplainant} handleClose={() => setShowComplainant(false)}/>*/}
            <Form>
                <Form.Group className={styles.group}>
                    <Form.Label className={styles.groupLabel}>Datos generales</Form.Label>
                    <Row style={{ marginTop: "0.2rem" }}>
                        <Col md={2}>
                            <Form.Label as={Col} bsPrefix={styles.label}>N° de Reporte</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.complaint_report_id} disabled/>
                        </Col>
                        <Col md={2}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Tipo de Recepción</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.type_reception} disabled />
                        </Col>
                        <Col md={3}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Estado</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="date-time" value={data.status} disabled />
                        </Col>
                        <Col>
                            <Form.Label as={Col} bsPrefix={styles.label}>Fecha de recepción</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.received_date} disabled />
                        </Col>                        
                    </Row>
                    <Row style={{ marginTop: "2rem" }}>
                        <Col md={2} className="me-3">
                            <Form.Label as={Col} bsPrefix={styles.label}>Fecha de creación</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.created_at} disabled/>
                        </Col>
                        <Col md={2}>
                            <Form.Label as={Col} bsPrefix={styles.label}>Número de SIGEDE</Form.Label>
                            <Form.Control bsPrefix={styles.input} type="text" value={data.sigede_number} disabled />
                        </Col>
                                             
                    </Row>
                </Form.Group>
            </Form>
        </>
    );

}

export default Step1;