import React, { useState } from "react";
import { Row, Col, Form, Button, Badge } from "react-bootstrap";
import { BsNewspaper } from "react-icons/bs";      
import { TbMinusVertical } from "react-icons/tb";  
import ReportCard from "./ReportCard";             
import styles from "./Contraloria.module.css";    

const Contraloria = ({ role, report, callback }) => {
  const [step, setStep] = useState(1);


  const steps = [
    <ReportCard
      key="reportcard"
      ReportId={report.report_contraloria_id || "-"}
      cgrDate={report.resolution_cgr?.date || "-"}
      fgeDate={report.resolution_fge?.date || "-"}
      udeaDate={report.resolution_udea?.date || "-"}
      receivedAt={report.received_at || "-"}
      resolutionCgrNumber={report.resolution_cgr?.number || "-"}
      resolutionFgeNumber={report.resolution_fge?.number || "-"}
      resolutionUdeaNumber={report.resolution_udea?.number || "-"}
      perjuryAmount={report.perjury_amount || "-"}
      institution={report.institution}
    />,
  ];


  const haveOptions = () => false;

  return (
    <div className={styles.container}>

      <Row>
        <Col md={8}>
          <Form.Label className={styles.title}>
            {`Nº ${report.report_contraloria_id || "-"}`}
          </Form.Label>
        </Col>
        <Col md={4} style={{ textAlign: "right" }}>
          <div className={styles.actionsContainer}>
            {report.status && (
              <Badge className={styles.status} pill>
                {report.status}
              </Badge>
            )}

            {haveOptions(report) && (

              <> {/* Opciones dropdown */} </>
            )}
          </div>
        </Col>
      </Row>

      <Row style={{ marginBottom: "1rem" }}>
        <Col md="auto">
          <Row>
            <Col style={{ textAlign: "center" }}>
              <BsNewspaper
                onClick={() => setStep(1)}
                className={`${styles.navIcon} ${step === 1 ? styles.active : ""}`}
              />
            </Col>
          </Row>

        </Col>

        <Col>
          {steps[step - 1]}
        </Col>
      </Row>

      <Row style={{ display: "flex" }}>
        {steps.length > 1 && step > 1 && step < steps.length ? (
          <>
            <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
              <Button bsPrefix={styles.next} onClick={() => setStep(step - 1)}>
                ANTERIOR
              </Button>
            </Col>
            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
              <Button bsPrefix={styles.next} onClick={() => setStep(step + 1)}>
                SIGUIENTE
              </Button>
            </Col>
          </>
        ) : step === 1 && steps.length > 1 ? (
          <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
            <Button bsPrefix={styles.next} onClick={() => setStep(step + 1)}>
              SIGUIENTE
            </Button>
          </Col>
        ) : steps.length > 1 && step === steps.length ? (
          <Col md={12} style={{ marginRight: "auto", textAlign: "left" }}>
            <Button bsPrefix={styles.next} onClick={() => setStep(step - 1)}>
              ANTERIOR
            </Button>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default Contraloria;
