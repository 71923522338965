import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Modal, Table, Badge } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import styles from "./Information.module.css";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import toast, { Toaster } from "react-hot-toast";
import ContraloriaModal from "../Edit/ContraloriaModal/ContraloriaModal";
import AddressModal from "../Edit/AddressModal/AddressModal";
import CrimesModal from "../Edit/CrimesModal/CrimesModal";
import DivisionModal from "../Edit/DivisionModal/DivisionModal";
import OfficialModal from "../Edit/OfficialModal/OfficialModal";
import ResolutionCgrModal from "../Edit/ResolutionCgrModal/ResolutionCgrModal";
import ResolutionFgeModal from "../Edit/ResolutionFgeModal/ResolutionFgeModal";
import ResolutionUdeaModal from "../Edit/ResolutionUdeaModal/ResolutionUdeaModal";

const Information = ({ contraloria, setContraloria, callback }) => {
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();

  const [isAddMode, setIsAddMode] = useState(false);  

  const [contraloriaModal, setContraloriaModal] = useState(false);
  const [institutionEdit, setInstitutionEdit] = useState(false);
  const [resolutionFgeModal, setResolutionFgeModal] = useState(false);
  const [resolutionUdeaModal, setResolutionUdeaModal] = useState(false);
  const [resolutionCgrModal, setResolutionCgrModal] = useState(false);
  const [crimesModal, setCrimesModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [officialModal, setOfficialModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [divisionModal, setDivisionModal] = useState(false);

  const [currentDivisionIndex, setCurrentDivisionIndex] = useState(0);
  const [currentCrimeIndex, setCurrentCrimeIndex] = useState(0);
  const [currentOfficialIndex, setCurrentOfficialIndex] = useState(0);
  const [currentContactIndex, setCurrentContactIndex] = useState(0);

  const handleNextDivisions = () => {
    if (currentDivisionIndex < contraloria.prosecutor_division.length - 1) {
      setCurrentDivisionIndex(currentDivisionIndex + 1);
    }
  };
  const handlePreviousDivisions = () => {
    if (currentDivisionIndex > 0) {
      setCurrentDivisionIndex(currentDivisionIndex - 1);
    }
  };

  const handleNextCrimes = () => {
    if (currentCrimeIndex < contraloria.prosecutors_divisions.length - 1) {
      setCurrentCrimeIndex(currentCrimeIndex + 1);
    }
  };
  const handlePreviousCrimes = () => {
    if (currentCrimeIndex > 0) {
      setCurrentCrimeIndex(currentCrimeIndex - 1);
    }
  };

  const handleNextOfficials = () => {
    if (currentOfficialIndex < contraloria.officials.length - 1) {
      setCurrentOfficialIndex(currentOfficialIndex + 1);
    }
  };
  const handlePreviousOfficials = () => {
    if (currentOfficialIndex > 0) {
      setCurrentOfficialIndex(currentOfficialIndex - 1);
    }
  };

  const handleNextContacts = () => {
    if (currentContactIndex < currentOfficial.contact.length - 1) {
      setCurrentContactIndex(currentContactIndex + 1);
    }
  };

  const handlePreviousContacts = () => {
    if (currentContactIndex > 0) {
      setCurrentContactIndex(currentContactIndex - 1);
    }
  };

  const currentDivision =
    contraloria.prosecutor_division[currentDivisionIndex] === undefined
      ? {}
      : contraloria.prosecutor_division[currentDivisionIndex];

  const currentCrime =
    contraloria.crimes[currentCrimeIndex] === undefined
      ? {}
      : contraloria.crimes[currentCrimeIndex];

  const currentOfficial =
    contraloria.officials[currentOfficialIndex] === undefined
      ? {}
      : contraloria.officials[currentOfficialIndex];

  const currentContact =
    currentOfficial.contact[currentContactIndex] === undefined
      ? {}
      : currentOfficial.contact[currentContactIndex];


  return (
    <>
      <Toaster />
      <ContraloriaModal
        data={contraloria}
        show={contraloriaModal}
        add={isAddMode}
        handleClose={() => setContraloriaModal(false)}
        callback={() => {
          setContraloriaModal(false);
          callback();
        }}
      />
      <AddressModal
        data={contraloria}
        show={addressModal}
        add={isAddMode}
        handleClose={() => setAddressModal(false)}
        callback={() => {
          setAddressModal(false);
          callback();
        }}
      />
      <CrimesModal
        data={contraloria}
        show={crimesModal}
        add={isAddMode}
        handleClose={() => {
          callback();
          setCrimesModal(false);
          setIsAddMode(false);
        }}
        currentCrimeIndex={currentCrimeIndex}
      />
      <OfficialModal
        data={contraloria}
        show={officialModal}
        add={isAddMode}
        handleClose={() => {
          callback();
          setOfficialModal(false);
          setIsAddMode(false);
        }}
        currentOfficialIndex={currentOfficialIndex}
      />
      <ResolutionFgeModal
        data={contraloria}
        show={resolutionFgeModal}
        add={isAddMode}
        handleClose={() => setResolutionFgeModal(false)}
        callback={() => {
          setResolutionFgeModal(false);
          callback();
        }}
      />
      <ResolutionUdeaModal
        data={contraloria}
        show={resolutionUdeaModal}
        add={isAddMode}
        handleClose={() => setResolutionUdeaModal(false)}
        callback={() => {
          setResolutionUdeaModal(false);
          callback();
        }}
      />
      <ResolutionCgrModal
        data={contraloria}
        show={resolutionCgrModal}
        add={isAddMode}
        handleClose={() => setResolutionCgrModal(false)}
        callback={() => {
          setResolutionCgrModal(false);
          callback();
        }}
      />
      <DivisionModal
        data={contraloria}
        show={divisionModal}
        add={isAddMode}
        handleClose={() => {
          callback();
          setDivisionModal(false);
          setIsAddMode(false);
        }}
        currentDivisionIndex={currentDivisionIndex}
      />
      <Form>
        <Row
          className={styles.group}
          onClick={(e) => {
            e.preventDefault();
            setContraloriaModal(true);
          }}
        >
          <Form.Label className={styles.groupLabel}>Contraloría</Form.Label>
          <Row className="mb-2">
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                N° de Reporte
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={contraloria.report_contraloria_id}
                disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Fecha posible
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={contraloria.possible_date}
                disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Monto
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={contraloria.perjury_amount}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Descripción
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={contraloria.description}
                disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Explicación del caso
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={contraloria.fact_description}
                disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Director
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={contraloria.director}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Institución
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={contraloria.institution.name}
                disabled
              />
            </Col>
          </Row>
        </Row>
        <Row className={styles.group}>
          {contraloria.address != null && contraloria.address.length > 0 && (
          <Row
            onClick={(e) => {
              e.preventDefault();
              setAddressModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>Dirección</Form.Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Departamento
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.address.department.name}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Ciudad
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.address.city.name}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Barrio
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.address.neighborhood.name}
                  disabled
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Calle principal
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.address.street.principal}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Calle secundaria
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.address.street.secondary}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Número de casa
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.address.houseNumber}
                  disabled
                />
              </Col>
              <Row style={{ marginBottom: "1rem" }}>
                <Col>
                  <Form.Label bsPrefix={styles.label} as={Col}>
                    Descripción
                  </Form.Label>
                  <Form.Control
                    bsPrefix={styles.input}
                    value={contraloria.address.description}
                    disabled
                  />
                </Col>
              </Row>
            </Row>
          </Row>
          )}
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setCrimesModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Hecho punible{" "}
                  {contraloria.crimes.length > 0 && (
                    <>
                      {" "}
                      {currentCrimeIndex + 1} de {contraloria.crimes.length}
                    </>
                  )}
                </Form.Label>
              </Col>
            </Row>
            {contraloria.crimes.length > 0 && (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Número
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={`Art. ${currentCrime.article} - ${currentCrime.name}`}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Fecha posible
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentCrime.possible_date}
                      disabled
                    />
                  </Col>
                </Row>
              </>
            )}
          </Row>
          {contraloria.crimes.length > 1 && (
            <Row style={{ marginTop: "1rem" }}>
              <Col xs="auto">
                <Button
                  className="boton-over"
                  variant="secondary"
                  onClick={handlePreviousCrimes}
                  disabled={currentCrimeIndex === 0}
                >
                  {"<"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  className="boton-over"
                  variant="secondary"
                  onClick={handleNextCrimes}
                  disabled={currentCrimeIndex === contraloria.crimes.length - 1}
                >
                  {">"}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setOfficialModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Funcionario{" "}
                  {contraloria.officials.length > 0 && (
                    <>
                      {" "}
                      {currentOfficialIndex + 1} de{" "}
                      {contraloria.officials.length}
                    </>
                  )}
                </Form.Label>
              </Col>
            </Row>
            {contraloria.officials.length > 0 && (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Nombre
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentOfficial.firstName}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Apellido
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentOfficial.lastName}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Rol
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentOfficial.rol}
                      disabled
                    />
                  </Col>
                </Row>
              </>
            )}
            </Row>
            {contraloria.officials.length > 1 && (
              <Row style={{ marginTop: "1rem" }}>
                <Col xs="auto">
                  <Button
                    className="boton-over"
                    variant="secondary"
                    onClick={handlePreviousOfficials}
                    disabled={currentOfficialIndex === 0}
                  >
                    {"<"}
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    className="boton-over"
                    variant="secondary"
                    onClick={handleNextOfficials}
                    disabled={
                      currentOfficialIndex === contraloria.officials.length - 1
                    }
                  >
                    {">"}
                  </Button>
                </Col>
              </Row>
            )}
          
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setResolutionFgeModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Resolución FGE
                </Form.Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Número de resolución
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.resolution_fge.number}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Fecha de resolución
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.resolution_fge.date}
                  disabled
                />
              </Col>
            </Row>
          </Row>
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setResolutionUdeaModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Resolución UDEA
                </Form.Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Número de resolución
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.resolution_udea.number}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Fecha de resolución
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.resolution_udea.date}
                  disabled
                />
              </Col>
            </Row>
          </Row>
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setResolutionCgrModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Resolución CGR
                </Form.Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Número de resolución
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.resolution_cgr.number}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label} as={Col}>
                  Fecha de resolución
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={contraloria.resolution_cgr.date}
                  disabled
                />
              </Col>
            </Row>
          </Row>
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setDivisionModal(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Unidad fiscal{" "}
                  {contraloria.prosecutor_division.length > 0 && (
                    <>
                      {" "}
                      {currentDivisionIndex + 1} de {contraloria.prosecutor_division.length}
                    </>
                  )}
                </Form.Label>
              </Col>
            </Row>
            {contraloria.prosecutor_division.length > 0 && (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Nombre
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentDivision.name}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Fiscal
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentDivision.prosecutor?.firstName + " " + currentDivision.prosecutor?.lastName}
                      disabled
                    />
                  </Col>
                </Row>
              </>
            )}
          </Row>
          {contraloria.prosecutor_division.length > 1 && (
            <Row style={{ marginTop: "1rem" }}>
              <Col xs="auto">
                <Button
                  className="boton-over"
                  variant="secondary"
                  onClick={handlePreviousDivisions}
                  disabled={currentDivisionIndex === 0}
                >
                  {"<"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  className="boton-over"
                  variant="secondary"
                  onClick={handleNextDivisions}
                  disabled={currentDivisionIndex === contraloria.prosecutor_division.length - 1}
                >
                  {">"}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Form>
    </>
  );
};

export default Information;
