import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./CrimesModal.module.scss";

const CrimesModal = ({
  show,
  handleClose,
  callback,
  data,
  add,
  currentCrimeIndex
}) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [crime, setCrime] = useState({});

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  useEffect(() => {
    if (!add) {
      if (
        data &&
        data.crimes &&
        currentCrimeIndex !== undefined &&
        data.crimes[currentCrimeIndex] !== undefined
      ) {
        const currentCrime = data.crimes[currentCrimeIndex];
        setCrime({
          crimeId: currentCrime.crime_id,
          possibleDate: currentCrime.possible_date
        });
      }
    } else {
      const currentCrime = data.crime[currentCrimeIndex];
      setCrime({
        crimeId: "",
        possibleDate: ""
      });
    }
  }, [data, currentCrimeIndex, add]);

  const buildBody = () => {
    return {
      crime_id: crime.crimeId,
      possible_date: crime.possibleDate
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/contraloria/${data.report_contraloria_id}/crimes/${data.crimes[currentCrimeIndex].crime_id}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        callback();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
      });
  };

  const handleInputChange = (field, value) => {
    setCrime((prevCrime) => ({
      ...prevCrime,
      [field]: value,
    }));
  };

  const handleSave = () => {
    const URL = `${process.env.REACT_APP_API_URL}/contraloria/${data.report_contraloria_id}/crime`;
    axios
      .post(URL, buildBody(), prepareHeaders())
      .then((res) => {
        toast.success("Crimen registrado exitosamente", {
          position: "top-center",
        });
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar el crimen ", {
          position: "top-center",
        });
        console.log(err);
      });
  };

  return (data.crimes[currentCrimeIndex] !== undefined &&
    crime !== undefined) ||
    add === true ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {add ? "Agregar Hecho punible" : "Hechos Punibles"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6>Hecho Punible {currentCrimeIndex + 1}</h6>
            <Row className="mt-2 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    value={crime.crimeId}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("crimeId", e.target.value)
                    }
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingCrimeId"
                    className={styles.label}
                  >
                    Número de crimen
                  </Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    type="text"
                    value={crime.possibleDate}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("possibleDate", e.target.value)
                    }
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingPossibleDate"
                    className={styles.label}
                  >
                    Fecha possible
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default CrimesModal;
