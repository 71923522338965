import React, { useEffect, useState } from "react";
import { Button, Form, Table, Col, Row, Modal } from "react-bootstrap";
import styles from "../../DenunciaForm.module.scss";
import { FiTrash } from "react-icons/fi";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import DenunciantesModal from "../../DenunciantesModal/DenunciantesModal";
import { IoPersonCircleOutline } from "react-icons/io5";
import AddPersonModal from "../../../modals/AddPersonModal";
import PersonModal from "../../../modals/PersonModal/PersonModal";

const Testigos = ({ next, previous, witnesses, setWitnesses }) => {
  //Sam
  const [isCreate, setIsCreate] = useState(false);
  const [person, setPerson] = useState({
    documentType: "CI",
    documentNumber: null,
    firstName: "",
    lastName: "",
    birthDate: null,
    gender: "",
    maritalStatus: "",
    profession: "",
    alias: "",
    addresses: [],
    contacts: [],
    personInstitutions: [],
    agents: [],
  });
  const [isAddPerson, setIsAddPerson] = useState(false);
  const { keycloak } = useKeycloak();

  const [isDetail, setIsDetail] = useState(false);

  const handleNext = (e) => {
    e.preventDefault();
    next(witnesses);
  };

  const skip = (e) => {
    e.preventDefault();
    next([]);
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    previous(witnesses);
  };

  const handleSubmit = async () => {
    await setWitnesses([...witnesses, person]);
  };

  const removeWitness = (e, witness) => {
    e.preventDefault();
    let aux = [];
    for (let current of witnesses) {
      if (current !== witness) {
        aux.push(current);
      }
    }
    setWitnesses(aux);
  };

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };
  return (
    <>
      {/* PersonDetailsModal */}
      <PersonModal
        show={isDetail}
        onHide={() => setIsDetail(false)}
        personId={person.personId}
      />

      <DenunciantesModal
        showModal={isCreate}
        onHide={() => {
          setIsCreate(false);
          setPerson({});
        }}
        setModal={setIsCreate}
        setPerson={setPerson}
        person={person}
        person_id={person.id}
        handleSubmit={handleSubmit}
        isDenunciado={false}
      />

      <AddPersonModal
        title={"Agregar Testigo"}
        show={isAddPerson}
        onHide={() => setIsAddPerson(false)}
        setComplainants={setWitnesses}
        complainants={witnesses}
        setIsCreate={setIsCreate}
        isDenunciado={false}
      />
      <Form>
        <Row>
          <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
            <Button bsPrefix={styles.next} onClick={() => setIsAddPerson(true)}>
              AGREGAR
            </Button>
          </Col>
        </Row>
        {witnesses && witnesses.length > 0 ? (
          <Table className={styles.table} style={{ marginTop: "1rem" }}>
            <thead>
              <th>Nombres y apellidos</th>
              <th>Tipo de documento</th>
              <th>Número de documento</th>
              <th>Acciones</th>
            </thead>
            <tbody>
              {witnesses.map((witness) => (
                <tr>
                  <td>{`${witness.firstName} ${witness.lastName}`}</td>
                  <td>{witness.documentType}</td>
                  <td>{witness.documentNumber}</td>
                  <td>
                    <IoPersonCircleOutline
                      onClick={(e) => {
                        e.preventDefault();
                        setPerson(witness);
                        setIsDetail(true);
                      }}
                      title="Ver"
                      className={styles.icon}
                    />
                    &nbsp;
                    <FiTrash
                      onClick={(e) => removeWitness(e, witness)}
                      title="Descartar"
                      className={styles.icon}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <></>
        )}

        <Row
          className="mt-4"
          style={{
            display: "flex",
            borderTop: "1px solid silver",
            paddingTop: ".7rem",
          }}
        >
          <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
            <Button bsPrefix={styles.next} onClick={handlePrevious}>
              ANTERIOR
            </Button>
          </Col>
          {witnesses.length > 0 ? (
            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
              <Button bsPrefix={styles.next} onClick={handleNext}>
                SIGUIENTE
              </Button>
            </Col>
          ) : (
            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
              <Button bsPrefix={styles.next} onClick={skip}>
                OMITIR
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
};

export default Testigos;
