import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./ResolutionFgeModal.module.scss";

const ResolutionFgeModal = ({
  show,
  handleClose,
  callback,
  data,
  add,
}) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [resolutionFgeNumber, setResolutionFgeNumber] = useState(undefined);
  const [resolutionFgeDate, setResolutionFgeDate] = useState("");

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  useEffect(() => {
    if (data) {
      setResolutionFgeNumber(data.resolution_fge.resolution_fge_number);
      setResolutionFgeDate(data.resolution_fge.resolution_fge_date);
    }
  }, [data]);

  const buildBody = () => {
    return {
      resolution_fge: {
        resolution_fge_number: resolutionFgeNumber,
        resolution_fge_date: resolutionFgeDate
      }
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/dnit/${data.dnit_report_id}/resolutionFge/${data.resolution_fge.resolution_fge_number}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        callback();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
      });
  };

  return data ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Resolución FGE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mt-2 mb-4">
              <Form.Group
                className="mb-3"
                as={Col}
              >
                <Form.Floating>
                  <Form.Control
                    id="floatingResolutionFgeNumber"
                    required={!preCarga}
                    type="text"
                    value={resolutionFgeNumber}
                    className={styles.input}
                    onChange={(e) => {
                      //setResolutionFgeNumber(e.target.value);
                    }}
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingResolutionFgeNumber"
                    className={styles.label}
                  >
                    Número
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingResolutionFgeDate"
                    required={!preCarga}
                    type="text"
                    value={resolutionFgeDate}
                    className={styles.input}
                    onChange={(e) => {
                      //setResolutionFgeDate(e.target.value);
                    }}
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingResolutionFgeDate"
                    className={styles.label}
                  >
                    Fecha
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};
export default ResolutionFgeModal;
