import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Table, Badge } from "react-bootstrap";
import styles from "./Steps.module.scss";
import { checkRoles } from "../../../../../Keycloak";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import AgentModal from "../../DenunciasContext/Edit/AgentModal/AgentModal";

const Step5 = ({
  data,
  callback,
  denunciante,
  denunciado,
  testigo,
  currentComplainantIndex,
  currentDefendantIndex,
  currentWitnessIndex,
}) => {
  const { keycloak } = useKeycloak();
  const [person, setPerson] = useState(null);
  const [showAgent, setShowAgent] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [mandatorio, setMandatorio] = useState(false);

  useEffect(() => {
    if (data && (denunciante || denunciado || testigo)) {
      let selectedPerson = null;

      if (denunciante) {
        selectedPerson = data.complainants[currentComplainantIndex] || null;
      } else if (denunciado) {
        selectedPerson = data.defendants[currentDefendantIndex] || null;
      } else if (testigo) {
        selectedPerson = data.witnesses[currentWitnessIndex] || null;
      }

      if (selectedPerson) {
        setPerson(selectedPerson);
      }
    }
  }, [
    data,
    denunciante,
    denunciado,
    testigo,
    currentComplainantIndex,
    currentDefendantIndex,
    currentWitnessIndex,
  ]);

  const prepareHeaders = () => {
    return {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  return (
    <>
      <AgentModal
        data={selectedAgent}
        show={showAgent}
        handleClose={() => setShowAgent(false)}
      />
      <Form>
        <Form.Group className={styles.group}>
          <Form.Label className={styles.groupLabel}>Agentes</Form.Label>
          {person && person.agents && person.agents.length > 0 ? (
            <>
              <Table className={styles.table} style={{ marginTop: "0.2rem" }}>
                <thead>
                  <tr>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Fecha de nacimiento</th>
                    <th>Nro de documento</th>
                    <th>Nro de matrícula</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {person.agents.map((agent, index) => (
                    <tr key={index}>
                      <td>{agent.first_name}</td>
                      <td>{agent.last_name}</td>
                      <td>{agent.birth_date}</td>
                      <td>{agent.document_number}</td>
                      <td>{agent.matricula_number}</td>
                      <td>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedAgent(agent);
                            setShowAgent(true);
                          }}
                          bsPrefix={styles.iconBtn}
                        >
                          Ver
                        </Button>
                        &nbsp;
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <Row>
              <Form.Label className={styles.noContent}>
                No hay elementos
              </Form.Label>
            </Row>
          )}
          </Form.Group><br/>
          {person && denunciante && (<>
            <Form.Group as={Col} md={2} controlId="mandatoryValidation">
              <Form.Check
                label="Obligatorio"
                type="switch"
                name="obligatorio"
                id="obligatorio"
                style={{
                  accentColor: "#AD0039",
                  marginTop: "auto",
                  marginBottom: "auto",
                  paddingBottom: "0px",
                  paddingRight: "0px",
                }}
                checked={person.mandatory}
                className={styles.label}
              />
            </Form.Group>
          </>)}
        
      </Form>
    </>
  );
};

export default Step5;
