import { Row, Col, Form, Badge, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import styles from "./DenunciaSpecific.module.css";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { TbMinusVertical } from "react-icons/tb";
import { GoArrowRight, GoArrowLeft } from "react-icons/go";
import { FaScaleBalanced } from "react-icons/fa6";
import { useKeycloak } from "@react-keycloak/web";
import { MdPerson } from "react-icons/md";
import axios from "axios";

const DenunciaSpecific = ({ data, callback, denunciante, denunciado, testigo, setDenunciante, setDenunciado, setTestigo, currentComplainantIndex, currentDefendantIndex, currentWitnessIndex, setCurrentComplainantIndex, setCurrentDefendantIndex, setCurrentWitnessIndex }) => {

    const [step, setStep] = useState(1);
    const [person, setPerson] = useState(null);

    const { keycloak } = useKeycloak();

    useEffect(() => {
        if (data && (denunciante || denunciado || testigo)) {
            let selectedPerson = null;

            if (denunciante) {
                selectedPerson = data.complainants || null;
            } else if (denunciado) {
                selectedPerson = data.defendants || null;
            } else if (testigo) {
                selectedPerson = data.witnesses || null;
            }

            if (selectedPerson) {
                setPerson(selectedPerson);
            }
        }
    }, [data, denunciante, denunciado, testigo]);

    const handleNext = () => {

        if (denunciante) {
            if (currentComplainantIndex < data.complainants.length - 1) {
                setCurrentComplainantIndex(currentComplainantIndex + 1);
            }
        } else if (denunciado) {
            if (currentDefendantIndex < data.defendants.length - 1) {
                setCurrentDefendantIndex(currentDefendantIndex + 1);
            }
        } else if (testigo) {
            if (currentWitnessIndex < data.witnesses.length - 1) {
                setCurrentWitnessIndex(currentWitnessIndex + 1);
            }
        }
    };

    const handlePrevious = () => {

        if (denunciante) {
            if (currentComplainantIndex > 0) {
                setCurrentComplainantIndex(currentComplainantIndex - 1);
            }
        } else if (denunciado) {
            if (currentDefendantIndex > 0) {
                setCurrentDefendantIndex(currentDefendantIndex - 1);
            }
        } else if (testigo) {
            if (currentWitnessIndex > 0) {
                setCurrentWitnessIndex(currentWitnessIndex - 1);
            }
        }
    };

    const currentData = denunciante
        ? (data.complainants[currentComplainantIndex] || {})
        : denunciado
            ? (data.defendants[currentDefendantIndex] || {})
            : testigo
                ? (data.witnesses[currentWitnessIndex] || {})
                : {};

    return (
        <div className={styles.container}>
            <Row>
                <Col md={8}>
                    <Form.Label className={styles.title}>
                        {denunciante ? "Denunciante" : denunciado ? "Denunciado" : testigo ? "Testigo" : ""}{" "}
                        {person && person.length > 1 && (
                            <>
                                {" "}
                                {denunciante ? ((currentComplainantIndex + 1) + " de ") : denunciado ? ((currentDefendantIndex + 1) + " de ") : testigo ? ((currentWitnessIndex + 1) + " de ") : ""}
                                {person.length}
                            </>
                        )}
                    </Form.Label>
                </Col>
                <Col>
                    {person && person.length > 1 && (
                        <Row style={{ marginTop: "1rem" }} className="justify-content-end">
                            <Col xs="auto">
                                <Button
                                    className="boton-over"
                                    variant="secondary"
                                    onClick={handlePrevious}
                                    disabled={denunciante ? (currentComplainantIndex === 0) : denunciado ? (currentDefendantIndex === 0) : testigo ? (currentWitnessIndex === 0) : ""}
                                >
                                    {<GoArrowLeft />}
                                </Button>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    className="boton-over"
                                    variant="secondary"
                                    onClick={handleNext}
                                    disabled={denunciante ? (currentComplainantIndex === person.length - 1) : denunciado ? (currentDefendantIndex === person.length - 1) : testigo ? (currentWitnessIndex === person.length - 1) : ""}

                                    style={{ marginRight: "0" }}
                                >
                                    {<GoArrowRight />}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
                <Col md="auto">

                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <MdPerson className={`${styles.navIcon} ${step === 1 ? styles.active : ""}`} onClick={(e) => { setStep(1) }} />
                        </Col>
                    </Row>
                    {denunciante ? (
                        <><Row>
                            <Col style={{ textAlign: "center" }}>
                                <TbMinusVertical className={`${styles.navIcon}`} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "center" }}>
                                <FaScaleBalanced className={`${styles.navIcon} ${step === 2 ? styles.active : ""}`} onClick={(e) => { setStep(2); }} />
                            </Col>
                        </Row></>
                    ) : (<></>)}
                    {denunciado ? (
                        <><Row>
                            <Col style={{ textAlign: "center" }}>
                                <TbMinusVertical className={`${styles.navIcon}`} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "center" }}>
                                <BsFillPersonLinesFill className={`${styles.navIcon} ${step === 3 ? styles.active : ""}`} onClick={(e) => { setStep(3); }} />
                            </Col>
                        </Row></>
                    ) : (<></>)}
                </Col>
                <Col>
                    {
                        step === 1 ? <Step1 data={data} denunciante={denunciante} denunciado={denunciado} testigo={testigo} setDenunciante={setDenunciante} setDenunciado={setDenunciado} setTestigo={setTestigo} currentComplainantIndex={currentComplainantIndex} currentDefendantIndex={currentDefendantIndex} currentWitnessIndex={currentWitnessIndex} /> : (
                            step === 2 ? <Step2 data={data} denunciante={denunciante} denunciado={denunciado} testigo={testigo} currentComplainantIndex={currentComplainantIndex} currentDefendantIndex={currentDefendantIndex} currentWitnessIndex={currentWitnessIndex} /> : (
                                step === 3 ? <Step3 data={data} denunciante={denunciante} denunciado={denunciado} testigo={testigo} currentComplainantIndex={currentComplainantIndex} currentDefendantIndex={currentDefendantIndex} currentWitnessIndex={currentWitnessIndex} /> : (
                                    <></>
                                )
                            )
                        )
                    }
                </Col>
            </Row>
            <Row style={{ display: "flex" }}>
            {
                    step === 1 && testigo !== true ? (
                        denunciante ? (
                            <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                                <Button bsPrefix={styles.next} onClick={(e) => { setStep(step+1) }}>SIGUIENTE</Button>
                            </Col>
                        ) : (
                            <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                                <Button bsPrefix={styles.next} onClick={(e) => { setStep(step+2) }}>SIGUIENTE</Button>
                            </Col>
                        )
                        
                    )
                    : (
                        denunciante && testigo !== true ? (
                            <Col md={12} style={{ marginRight: "auto", textAlign: "left" }}>
                                <Button bsPrefix={styles.next} onClick={(e) => { setStep(step-1) }}>ANTERIOR</Button>
                            </Col>
                        ) : ( testigo !== true ? (
                            <Col md={12} style={{ marginRight: "auto", textAlign: "left" }}>
                                <Button bsPrefix={styles.next} onClick={(e) => { setStep(step-2) }}>ANTERIOR</Button>
                            </Col>
                        ):(<></>)
                        )
                    )
                }
            </Row>

        </div>
    )

}

export default DenunciaSpecific;