import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./PersonInstitutionModal.module.scss";

const PersonInstitutionModal = ({ show, handleClose, callback, data, denunciante, denunciado, testigo }) => {
  const [preCarga, setPreCarga] = useState(true);
  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  return data ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Instituciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className={styles.group}>
              <Row style={{ marginBottom: "1rem" }}>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingInstiution"
                      required={!preCarga}
                      type="text"
                      value={data.institution?.name || ""}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingInstitution" className={styles.label}>
                      Institución Laboral
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingRol"
                      required={!preCarga}
                      type="text"
                      value={data?.rol || ""}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingRol" className={styles.label}>
                      Rol
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingRolDescription"
                      required={!preCarga}
                      type="text"
                      value={data?.rol_description || ""}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingRolDescription" className={styles.label}>
                      Descripción del rol
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: "1rem" }}>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingFromDate"
                      required={!preCarga}
                      type="text"
                      value={data?.from_date || ""}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingFromDate" className={styles.label}>
                      Fecha de ingreso
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingToDate"
                      required={!preCarga}
                      type="text"
                      value={data?.to_date || ""}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingToDate" className={styles.label}>
                      Fecha de salida
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default PersonInstitutionModal;
