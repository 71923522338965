import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Row,
  Dropdown,
  Form,
  Table,
  Col,
  Pagination,
} from "react-bootstrap";
import styles from "./AddPersonModal.module.css";
import { GoPersonAdd } from "react-icons/go";
import { IoPersonAdd, IoPersonCircleOutline } from "react-icons/io5";
import { MdCleaningServices } from "react-icons/md";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import PersonModal from "../PersonModal/PersonModal";

const AddPersonModal = ({
  show,
  onHide,
  setIsCreate,
  setComplainants,
  complainants,
  title,
}) => {
  const [searchResults, setSearchResults] = useState({ content: [] });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPerson, setSelectedPerson] = useState({});
  const [selectedPersonName, setSelectedPersonName] = useState("");
  const [selectedPersonDocument, setSelectedPersonDocument] = useState("");
  const [isDetail, setIsDetail] = useState(false);
  const { keycloak } = useKeycloak();

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }
  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const searchPerson = async () => {
    let uri = `/person?page=${page}&pageSize=10`;
    if (selectedPersonDocument.length > 0) {
      uri = uri + `&document_number=${selectedPersonDocument}`;
    }
    if (selectedPersonName.length > 0) {
      uri = uri + `&name=${toTitleCase(selectedPersonName)}`;
    }

    axios
      .get(process.env.REACT_APP_API_URL + uri, prepareHeaders())
      .then((res) => {
        setSearchResults(res.data);
        setTotalPages(res.data.totalPages || 1);
      });
  };
  useEffect(() => {
    searchPerson();
  }, [selectedPersonDocument, selectedPersonName, page]);

  return (
    <>
      <PersonModal
        show={isDetail}
        onHide={() => setIsDetail(false)}
        personId={selectedPerson.personId}
      />

      <Modal size="lg" show={show && !isDetail} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-4">
            <Dropdown>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Control
                  type="text"
                  placeholder="Buscar por nombre"
                  style={{ width: "40vh" }}
                  value={selectedPersonName}
                  onChange={(e) => {
                    e.preventDefault();
                    setSelectedPersonName(e.target.value);
                  }}
                />
                <Form.Control
                  type="text"
                  placeholder="Buscar por número de documento"
                  style={{ width: "40vh" }}
                  value={selectedPersonDocument}
                  onChange={(e) => {
                    e.preventDefault();
                    setSelectedPersonDocument(e.target.value);
                  }}
                />
                <Button
                  onClick={() => {
                    setSelectedPersonName("");
                    setSelectedPersonDocument("");
                  }}
                  bsPrefix={styles.next}
                >
                  Limpiar <MdCleaningServices />
                </Button>
                <Button
                  onClick={() => {
                    setIsCreate(true);
                    onHide();
                  }}
                  bsPrefix={styles.next}
                >
                  Nuevo <GoPersonAdd />
                </Button>
              </div>
              <Table className={styles.table} style={{ marginTop: "1rem" }}>
                <thead>
                  <th>Nombres y apellidos</th>
                  <th>Tipo de documento</th>
                  <th>Número de documento</th>
                  <th></th>
                </thead>
                <tbody>
                  {searchResults.content &&
                    searchResults.content.length > 0 &&
                    searchResults.content.map((personSelected) => (
                      <tr>
                        <td>{`${personSelected.firstName} ${personSelected.lastName}`}</td>
                        <td>{personSelected.documentType}</td>
                        <td>{personSelected.documentNumber}</td>
                        <td>
                          <IoPersonCircleOutline
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedPerson(personSelected);
                              setIsDetail(true);
                            }}
                            title="Ver"
                            className={styles.icon}
                          />
                          &nbsp;
                          <IoPersonAdd
                            onClick={(e) => {
                              e.preventDefault();
                              setComplainants([
                                ...complainants,
                                personSelected,
                              ]);
                              onHide();
                            }}
                            title="Ver"
                            className={styles.icon}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Dropdown>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <Pagination>
                <Pagination.Prev
                  onClick={() => page > 1 && setPage(page - 1)}
                  disabled={page === 1}
                >
                  Anterior
                </Pagination.Prev>

                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === page}
                    onClick={() => setPage(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => page < totalPages && setPage(page + 1)}
                  disabled={page === totalPages || totalPages === 0}
                >
                  Siguiente
                </Pagination.Next>
              </Pagination>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPersonModal;
