import React from "react";
import { Button, Form, Col, Row, Table, Badge } from "react-bootstrap";
import styles from "./Steps.module.scss"
import { checkRoles } from "../../../../Keycloak";
import { useKeycloak } from "@react-keycloak/web";

const Step2 = ( { next, prev, data } ) => {

    const { keycloak } = useKeycloak();

    return (
        <>
            <Form>
                <Form.Group className={styles.group}>
                    <Form.Label className={styles.groupLabel}>Denunciados</Form.Label>
                    {
                        data.defendants && data.defendants.length > 0 ? (
                            <>
                                {
                                    data.reserved && !["fiscal-adjunto", "fiscal"].includes(checkRoles(keycloak)) ? (
                                        <Row>
                                            <Form.Label className={styles.noContent}>Información reservada</Form.Label>
                                        </Row>
                                    )
                                    : (
                                        <Table className={styles.table} style={{ marginTop: "0.2rem" }}>
                                            <thead>
                                                <th>Nombres</th>
                                                <th>Apellidos</th>
                                                <th>Fecha de nacimiento</th>
                                                <th>Tipo de documento</th>
                                                <th>Número de documento</th>
                                                <th>Cargo</th>                                        
                                                <th>Acciones</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.defendants.map((defendant) => (
                                                        <tr>
                                                            <td>{defendant.firstName}</td>
                                                            <td>{defendant.lastName}</td>
                                                            <td>{defendant.birthDate}</td>
                                                            <td>{defendant.document.type}</td>
                                                            <td>{defendant.document.number}</td>
                                                            <td>{defendant.publiclyExposedPerson ? defendant.publiclyExposedPerson.title : "-"}</td>
                                                            <td align="center"><Button bsPrefix={styles.iconBtn}>Ver</Button></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    )
                                }
                            </>
                        )
                        : 
                        <Row>
                            <Form.Label className={styles.noContent}>No hay elementos</Form.Label>
                        </Row>
                    }
                </Form.Group>
                <Form.Group className={styles.group}>
                    <Form.Label as={Col} className={styles.groupLabel}>Perjudicados</Form.Label>
                    {
                        data.injuredParties && data.injuredParties.length > 0 ? (
                            <>
                                {
                                    data.injuredParties.map((injuredParty) => (
                                        <Badge bg="secondary">{injuredParty}</Badge>
                                    ))
                                }
                            </>
                        )
                        :
                        <Row>
                            <Form.Label className={styles.noContent}>No hay elementos</Form.Label>
                        </Row>
                    }
                </Form.Group>
            </Form>
        </>
    );

}

export default Step2;