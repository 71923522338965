import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./ContraloriaModal.module.scss";

const ContraloriaModal = ({
  show,
  handleClose,
  callback,
  data,
  add,
}) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [reportContraloriaId, setReportContraloriaId] = useState(undefined);
  const [possibleDate, setPossibleDate] = useState("");
  const [perjuryAmount, setPerjuryAmount] = useState("");
  const [description, setDescription] = useState("");
  const [factDescription, setFactDescription] = useState("");
  const [institution, setInstitution] = useState("");
  const [institutionId, setInstitutionId] = useState(undefined);
  const [director, setDirector] = useState("");  

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  useEffect(() => {
    if (data) {
      setReportContraloriaId(data.report_contraloria_id);
      setPossibleDate(data.possible_date);
      setPerjuryAmount(data.perjury_amount);
      setDescription(data.description);
      setFactDescription(data.fact_description);
      setInstitutionId(data.institution.institution_id);
      setInstitution(data.institution.name);
      setDirector(data.director);
    }
  }, [data]);

  const buildBody = () => {
    return {
      report_contraloria_id: reportContraloriaId,
      possible_date: possibleDate,
      perjury_amount: perjuryAmount,
      description,
      fact_description: factDescription,
      institution:{
        institution_id: institutionId,
        name:institution
      },
      director
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/contraloria/${data.report_contraloria_id}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        callback();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
      });
  };

  return data ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Datos Generales Contraloría</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mt-2 mb-4">
              <Form.Group
                className="mb-3"
                as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingPossibleDate"
                    required={!preCarga}
                    type="text"
                    value={possibleDate}
                    className={styles.input}
                    onChange={(e) => {
                      //setPossibleDate(e.target.value);
                    }}
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingPossibleDate"
                    className={styles.label}
                  >
                    Fecha posible
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingPerjuryAmount"
                    required={!preCarga}
                    type="text"
                    value={perjuryAmount}
                    className={styles.input}
                    onChange={(e) => {
                      //setPerjuryAmount(e.target.value);
                    }}
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingPerjuryAmount"
                    className={styles.label}
                  >
                    Monto
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingDescription"
                      type="text"
                      value={description}
                      className={styles.input}
                      onChange={(e) => {
                        //setDescription(e.target.value);
                      }}
                      readOnly
                    >
                    </Form.Control>
                    <Form.Label
                      htmlFor="floatingDescription"
                      className={styles.label}
                    >
                      Descripción
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingFactDescription"
                      required={!preCarga}
                      type="text"
                      value={factDescription}
                      className={styles.input}
                      onChange={(e) => {
                        //setFactDescription(e.target.value);
                      }}
                      readOnly
                    />
                    <Form.Label
                      htmlFor="floatingFactDescription"
                      className={styles.label}
                    >
                      Explicación del caso
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingInstitution"
                      required={!preCarga}
                      value={institution}
                      className={styles.input}
                      onChange={(e) => {
                        //setInstitution(e.target.value);
                      }}
                      readOnly
                    />
                    <Form.Label
                      htmlFor="floatingInstitution"
                      className={styles.label}
                    >
                      Institución
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingDirector"
                      type="text"
                      value={director}
                      className={styles.input}
                      onChange={(e) => {
                        //setDirector(e.target.value);
                      }}
                      readOnly
                    >
                    </Form.Control>
                    <Form.Label
                      htmlFor="floatingDirector"
                      className={styles.label}
                    >
                      Director
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};
export default ContraloriaModal;
