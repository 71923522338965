import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Modal, Table, Badge } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import styles from "./Information.module.css";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import DenunciaSpecific from "../../DenunciaSpecific/DenunciaSpecific";
import DivisionModal from "../Edit/DivisionModal/DivisionModal";
import CrimesModal from "../Edit/CrimesModal/CrimesModal";
import DatosDelHecho from "../../DatosDelHecho/DatosDelHecho";

const Information = ({ denuncia, setDenuncia, callback }) => {
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();

  const [datosDelHecho, setDatosDelHecho] = useState(false);
  const [denunciante, setDenunciante] = useState(false);
  const [denunciado, setDenunciado] = useState(false);
  const [testigo, setTestigo] = useState(false);
  const [crimesModal, setCrimesModal] = useState(false);
  const [divisionModal, setDivisionModal] = useState(false);
  const [currentDivisionIndex, setCurrentDivisionIndex] = useState(0);
  const [currentCrimeIndex, setCurrentCrimeIndex] = useState(0);
  const [currentComplainantIndex, setCurrentComplainantIndex] = useState(0);
  const [currentDefendantIndex, setCurrentDefendantIndex] = useState(0);
  const [currentWitnessIndex, setCurrentWitnessIndex] = useState(0);
  

  const handleNextDivisions = () => {
    if (currentDivisionIndex < denuncia.prosecutors_divisions.length - 1) {
      setCurrentDivisionIndex(currentDivisionIndex + 1);
    }
  };
  const handlePreviousDivisions = () => {
    if (currentDivisionIndex > 0) {
      setCurrentDivisionIndex(currentDivisionIndex - 1);
    }
  };

  if (!denuncia.prosecutors_divisions) {
    console.error("La respuesta no contiene la propiedad 'prosecutors_divisions'.");
    // Asigna un valor predeterminado
    denuncia.prosecutors_divisions = [];
  }

  const currentDivision =
    denuncia.prosecutors_divisions && denuncia.prosecutors_divisions[currentDivisionIndex] === undefined
      ? {}
      : denuncia.prosecutors_divisions[currentDivisionIndex];


  return (
    <>
      <Toaster />
      <Form>
        <h2>Denuncia</h2>
        <Row>
          <Form.Label className={styles.groupLabel}>Datos generales</Form.Label>
          <Row className="mb-2">
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Tipo de denuncia
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={denuncia.type}
                disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Reservada
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={denuncia.reserved ? "Si" : "No"}
                disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Fecha y hora de recepcion
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={denuncia.created_at}
                disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Recibida por
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={
                  denuncia.createdBy.firstName +
                  " " +
                  denuncia.createdBy.lastName
                }
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Nro. SIGEDE
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={denuncia.causeReference}
                disabled
              />
            </Col>
          </Row>
        </Row>
        <DenunciaSpecific
          callback={() => {
            callback();
          }}
          data={denuncia}
          denunciado={denunciado}
          denunciante={true}
          testigo={testigo}
          setDenunciado={setDenunciado}
          setDenunciante={setDenunciante}
          setTestigo={setTestigo}
          currentComplainantIndex={currentComplainantIndex}
          setCurrentComplainantIndex={setCurrentComplainantIndex}
        />
        <DenunciaSpecific
          callback={() => {
            callback();
          }}
          data={denuncia}
          denunciado={true}
          denunciante={denunciante}
          testigo={testigo}
          setDenunciado={setDenunciado}
          setDenunciante={setDenunciante}
          setTestigo={setTestigo}
          currentDefendantIndex={currentDefendantIndex}
          setCurrentDefendantIndex={setCurrentDefendantIndex}
        />
        <DenunciaSpecific
          callback={() => {
            callback();
          }}
          data={denuncia}
          denunciado={denunciado}
          denunciante={denunciante}
          testigo={true}
          setDenunciado={setDenunciado}
          setDenunciante={setDenunciante}
          setTestigo={setTestigo}
          currentWitnessIndex = {currentWitnessIndex}
          setCurrentWitnessIndex = {setCurrentWitnessIndex}
        />
        <DatosDelHecho
          callback={() => {
            callback();
          }}
          data={denuncia}
          datosDelHecho={true}
        />
        <DivisionModal
          data={denuncia}
          show={divisionModal}
          handleClose={() => {
            callback();
            setDivisionModal(false);
          }}
          currentDivisionIndex={currentDivisionIndex}
        />
        <br />
        <Row className={`${styles.group} ${styles.marginTop}`}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setDivisionModal(true);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Unidad fiscal{" "}
                  {denuncia.prosecutors_divisions && denuncia.prosecutors_divisions.length > 0 && (
                    <>
                      {" "}
                      {currentDivisionIndex + 1} de{" "}
                      {denuncia.prosecutors_divisions.length}
                    </>
                  )}
                </Form.Label>
              </Col>
            </Row>
            {denuncia.prosecutors_divisions && denuncia.prosecutors_divisions.length > 0 ? (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Nombre
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentDivision.name || ""}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Fiscal
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={
                        currentDivision.prosecutor.firstName +
                        " " +
                        currentDivision.prosecutor.lastName || ""
                      }
                      disabled
                    />
                  </Col>
                </Row>
              </>
            ) : (<span className="mt-3 mb-2">No existen unidades fiscales</span>)
            }
          </Row>
          {denuncia.prosecutors_divisions && denuncia.prosecutors_divisions.length > 1 && (
            <Row style={{ marginTop: "1rem" }}>
              <Col xs="auto">
                <Button
                  className="boton-over"
                  variant="secondary"
                  onClick={handlePreviousDivisions}
                  disabled={currentDivisionIndex === 0}
                >
                  {"<"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  className="boton-over"
                  variant="secondary"
                  onClick={handleNextDivisions}
                  disabled={
                    currentDivisionIndex ===
                    denuncia.prosecutors_divisions.length - 1
                  }
                >
                  {">"}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Form>
    </>
  );
};

export default Information;
