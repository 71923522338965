import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const DatosGeneralesPerson = ({ personData }) => {
  if (!personData) {
    return <div>Cargando datos generales...</div>;
  }

  const {
    documentType,
    documentNumber,
    firstName,
    lastName,
    birthDate,
    gender,
    maritalStatus,
    profession,
    alias,
  } = personData;

  return (
    <div>
      <h5>Datos Generales</h5>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Tipo de Documento</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={documentType || ""}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Número de Documento</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={documentNumber || ""}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Nombres</Form.Label>
            <Form.Control type="text" readOnly value={firstName || ""} />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Apellidos</Form.Label>
            <Form.Control type="text" readOnly value={lastName || ""} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Fecha de nacimiento</Form.Label>
            <Form.Control type="text" readOnly value={birthDate || ""} />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Género</Form.Label>
            <Form.Control type="text" readOnly value={gender || ""} />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Estado Civil</Form.Label>
            <Form.Control type="text" readOnly value={maritalStatus || ""} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Profesión</Form.Label>
            <Form.Control type="text" readOnly value={profession || ""} />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Alias</Form.Label>
            <Form.Control type="text" readOnly value={alias || ""} />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default DatosGeneralesPerson;
