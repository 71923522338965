import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./AddressModal.module.scss";

const AddressModal = ({
  show,
  handleClose,
  callback,
  data,
  add,
}) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [department, setDepartment] = useState(undefined);
  const [departmentId, setDepartmentId] = useState(undefined);
  const [city, setCity] = useState("");
  const [cityId, setCityId] = useState(undefined);
  const [neighborhood, setNeighborhood] = useState("");
  const [neighborhoodId, setNeighborhoodId] = useState(undefined);
  const [principal, setPrincipal] = useState("");
  const [secondary, setSecondary] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [description, setDescription] = useState("");

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  

  useEffect(() => {
    if(data.address != null){
      let addresses = data.address;
      if (data) {
        setDepartmentId(addresses.department.id);
        setDepartment(addresses.department.name);
        setCityId(addresses.city.id);
        setCity(addresses.city.name);
        setNeighborhoodId(addresses.neighborhood.id);
        setNeighborhood(addresses.neighborhood.name);
        setPrincipal(addresses.street.principal);
        setSecondary(addresses.street.secondary);
        setHouseNumber(addresses.houseNumber);
        setDescription(addresses.description);
      }
    };
  }, [data]);

  

  return data ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Dirección</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mt-2 mb-4">
              <Form.Group
                className="mb-3"
                as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingDepartment"
                    required={!preCarga}
                    type="text"
                    value={department}
                    className={styles.input}
                    onChange={(e) => {
                      //setDepartment(e.target.value);
                    }}
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingDepartment"
                    className={styles.label}
                  >
                    Departamento
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingCity"
                    required={!preCarga}
                    type="text"
                    value={city}
                    className={styles.input}
                    onChange={(e) => {
                      //setCity(e.target.value);
                    }}
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingCity"
                    className={styles.label}
                  >
                    Ciudad
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingneighborhood"
                    type="text"
                    value={neighborhood}
                    className={styles.input}
                    onChange={(e) => {
                      //setNeighborhood(e.target.value);
                    }}
                    readOnly
                  >
                  </Form.Control>
                  <Form.Label
                    htmlFor="floatingNeighborhood"
                    className={styles.label}
                  >
                    Barrio
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingPrincipal"
                    required={!preCarga}
                    type="text"
                    value={principal}
                    className={styles.input}
                    onChange={(e) => {
                      //setPrincipal.target.value);
                    }}
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingPrincipal"
                    className={styles.label}
                  >
                    Calle Principal
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingSecondary"
                    required={!preCarga}
                    value={secondary}
                    className={styles.input}
                    onChange={(e) => {
                      //setSecondary(e.target.value);
                    }}
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingSecondary"
                    className={styles.label}
                  >
                    Calle secundaria
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group
                className="mb-3"
                as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingHouseNumber"
                    type="text"
                    value={houseNumber}
                    className={styles.input}
                    onChange={(e) => {
                      //setHouseNumber(e.target.value);
                    }}
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingHouseNumber"
                    className={styles.label}
                  >
                    Número de casa
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Row className="mb-3">
                <Form.Group
                  className="mb-3"
                  as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingDescription"
                      type="text"
                      value={description}
                      className={styles.input}
                      onChange={(e) => {
                        //setDescription(e.target.value);
                      }}
                      readOnly
                    />
                    <Form.Label
                      htmlFor="floatingDescription"
                      className={styles.label}
                    >
                      Descripción
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default AddressModal;
