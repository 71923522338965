import React from "react";
import { Form, Col, Row, Table, Badge } from "react-bootstrap";
import styles from "./Steps.module.scss";
import { useKeycloak } from "@react-keycloak/web";
import { JudgmentType } from "../../../../constants/JudgmentType";

const Step6 = ({ next, prev, data }) => {
  const { keycloak } = useKeycloak();

  return (
    <>
      <Form>
        <Form.Group className={styles.group}>
          <Form.Label className={styles.groupLabel}>
            Divisiones Fiscales
          </Form.Label>
          {data.prosecutorDivisions && data.prosecutorDivisions.length > 0 ? (
            <Row>
              <Col md={12}>
                <Table className={styles.table} style={{ marginTop: "0.2rem" }}>
                  <thead>
                    <th style={{ textAlign: "center" }}>Division</th>
                    <th style={{ textAlign: "center" }}>Nombre</th>
                    <th style={{ textAlign: "center" }}>Apellido</th>
                  </thead>
                  <tbody>
                    {data.prosecutorDivisions.map((division) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          {division.prosecutorDivisionName}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {division.prosecutorFirstName}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {division.prosecutorLastName}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : (
            <Row>
              <Form.Label className={styles.noContent}>
                No hay elementos
              </Form.Label>
            </Row>
          )}
        </Form.Group>
      </Form>
    </>
  );
};

export default Step6;
