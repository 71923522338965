import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./DatosGeneralesModal.module.scss";

const DatosGeneralesModal = ({ show, handleClose, callback, data, denunciante, denunciado, testigo, person }) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [documentNumber, setDocumentNumber] = useState(undefined);
  const [documentType, setDocumentType] = useState(undefined);
  const [birthDate, setBirthDate] = useState(undefined);
  const [gender, setGender] = useState(undefined);
  const [maritalStatus, setMaritalStatus] = useState(undefined);
  const [profession, setProfession] = useState(undefined);
  const [alias, setAlias] = useState(undefined);

  const { keycloak } = useKeycloak();

  /*const [person, setPerson] = useState(null);

  useEffect(() => {
    if (data) {
      let selectedPerson = null;

      if (denunciante) {
        selectedPerson = data.denunciante;
      } else if (denunciado) {
        selectedPerson = data.denunciado;
      } else if (testigo) {
        selectedPerson = data.testigo;
      }

      if (selectedPerson) {
        setPerson(selectedPerson);
      }
    }
  }, [data, denunciante, denunciado, testigo]);*/

  console.log(data);
  console.log(person);

  useEffect(() => {
    if (person) {
      setDocumentType(person.document_type || "");
      setDocumentNumber(person.document_number || "");
      setFirstName(person.first_name || "");
      setLastName(person.last_name || "");
      setBirthDate(person.birth_date || "");
      setGender(person.gender || "");
      setMaritalStatus(person.marital_status || "");
      setProfession(person.profession || "");
      setAlias(person.alias || "");
    }
  }, [person]);

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  return data ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Datos Personales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {person ? (
            <><Row className="mt-2 mb-3">
              <Row className="mb-3">
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingFirstName"
                      required={!preCarga}
                      type="text"
                      value={firstName}
                      className={styles.input}
                      onChange={(e) => {
                        //setFirstName(e.target.value);
                      }}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingFirstName" className={styles.label}>
                      Nombres
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingLastName"
                      required={!preCarga}
                      type="text"
                      value={lastName}
                      className={styles.input}
                      onChange={(e) => {
                        //setLastName(e.target.value);
                      }}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingLastName" className={styles.label}>
                      Apellidos
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingDocumentType"
                      as="select"
                      value={documentType}
                      className={styles.input}
                      readOnly
                    >
                      <option value="">Seleccione tipo de Documento</option>
                      <option value="CI">Cédula de identidad</option>
                      <option value="PAS">Pasaporte</option>
                      <option value="MAT">
                        Matrícula de la Corte Suprema de Justicia
                      </option>
                    </Form.Control>
                    <Form.Label htmlFor="floatingDocumentType" className={styles.label}>
                      Tipo de documento
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingDocumentNumber"
                      required={!preCarga}
                      type="text"
                      value={documentNumber}
                      className={styles.input}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingDocumentNumber" className={styles.label}>
                      Número de documento
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingBirthDate"
                      required={!preCarga}
                      value={birthDate}
                      className={styles.input}
                      onChange={(e) => {
                        //setBirthDate(e.target.value);
                      }}
                      readOnly
                      type="text"
                      max={new Date().toISOString().split("T")[0]}
                    />
                    <Form.Label htmlFor="floatingBirthDate" className={styles.label}>
                      Fecha de nacimiento
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingGender"
                      type="text"
                      value={
                        gender === "M"
                          ? "Masculino"
                          : gender === "F"
                            ? "Femenino"
                            : gender}
                      className={styles.input}
                      onChange={(e) => {
                        //setGender(e.target.value);
                      }}
                      readOnly
                    >
                    </Form.Control>
                    <Form.Label htmlFor="floatingGender" className={styles.label}>
                      Genero
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingMaritalStatus"
                      type="text"
                      value={maritalStatus}
                      className={styles.input}
                      onChange={(e) => {
                        //setMaritalStatus(e.target.value);
                      }}
                      readOnly
                    >
                    </Form.Control>
                    <Form.Label htmlFor="floatingMaritalStatus" className={styles.label}>
                      Estado civil
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingProfession"
                      required={!preCarga}
                      type="text"
                      value={profession}
                      className={styles.input}
                      onChange={(e) => {
                        //setProfession(e.target.value);
                      }}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingProfession" className={styles.label}>
                      Profesión
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingAlias"
                      required={!preCarga}
                      type="text"
                      value={alias}
                      className={styles.input}
                      onChange={(e) => {
                        //setAlias(e.target.value || "");
                      }}
                      readOnly
                    />
                    <Form.Label htmlFor="floatingAlias" className={styles.label}>
                      Alias
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
            </Row>
            </>) : ( <></>)}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default DatosGeneralesModal;
