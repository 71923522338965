import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./DivisionModal.module.scss";

const DivisionModal = ({
  show,
  handleClose,
  callback,
  data,
  add,
  currentDivisionIndex
}) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [division, setDivision] = useState({});

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  useEffect(() => {
    if (!add) {
      if (
        data &&
        data.prosecutors_divisions &&
        currentDivisionIndex !== undefined &&
        data.prosecutors_divisions[currentDivisionIndex] !== undefined
      ) {
        const currentDivision = data.prosecutors_divisions[currentDivisionIndex];
        setDivision({
          divisionId: currentDivision.division_id,
          name: currentDivision.name,
          prosecutor:{
            firstName: currentDivision.prosecutor.firstName,
            lastName: currentDivision.prosecutor.lastName
          }
        });
      }
    } else {
      const currentDivision = data.prosecutors_divisions[currentDivisionIndex];
      setDivision({
        divisionId: "",
        name: "",
        prosecutor:{
          firstName: "",
          lastName: ""
        }
      });
    }
  }, [data, currentDivisionIndex, add]);

  const buildBody = () => {
    return {
      division_id: division.divisionId,
      name: division.name,
      prosecutor:{
        firstName: division.firstName,
        lastName: division.lastName
      }
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/dnit/${data.dnit_report_id}/prosecutors_divisions/${data.prosecutors_divisions[currentDivisionIndex].division_id}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        callback();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
      });
  };

  const handleInputChange = (field, value) => {
    setDivision((prevDivision) => ({
      ...prevDivision,
      [field]: value,
    }));
  };

  const handleSave = () => {
    const URL = `${process.env.REACT_APP_API_URL}/dnit/${data.dnit_report_id}/prosecutors_division`;
    axios
      .post(URL, buildBody(), prepareHeaders())
      .then((res) => {
        toast.success("Unidad Fiscal registrada exitosamente", {
          position: "top-center",
        });
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar la unidad fiscal ", {
          position: "top-center",
        });
        console.log(err);
      });
  };

  return (data.prosecutors_divisions[currentDivisionIndex] !== undefined &&
    division !== undefined) ||
    add === true ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {add ? "Agregar Unidad Fiscal" : "Unidades Fiscales"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6>Unidad Fiscal {currentDivisionIndex + 1}</h6>
            <Row className="mt-2 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    value={division.divisionId}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("divisionId", e.target.value)
                    }
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingDivisionId"
                    className={styles.label}
                  >
                    Número
                  </Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    type="text"
                    value={division.name}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("name", e.target.value)
                    }
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingName"
                    className={styles.label}
                  >
                    Nombre
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mt-2 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    value={data.prosecutors_divisions[currentDivisionIndex].prosecutor.firstName + " " + data.prosecutors_divisions[currentDivisionIndex].prosecutor.lastName}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("prosecutor", e.target.value)
                    }
                    readOnly
                  />
                  <Form.Label
                    htmlFor="floatingProsecutor"
                    className={styles.label}
                  >
                    Fical
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default DivisionModal;
