import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import styles from "./FuncionarioModal.module.css";

const FuncionarioModal = ({
  show,
  onHide,
  currentDefendant,
  defendants,
  setDefendants,
}) => {
  const [pepBoolean, setPepBoolean] = useState(false);
  const [publicOfficialBoolean, setPublicOfficialBoolean] = useState(false);

  const update = () => {
    if (currentDefendant) {
      console.log("update currentDefendant", currentDefendant);

      const updatedDefendants = defendants.map((defendant) =>
        defendant.documentNumber === currentDefendant.documentNumber
          ? currentDefendant
          : defendant
      );
      setDefendants(updatedDefendants);
    }
  };

  useEffect(() => {
      if (currentDefendant) {
          currentDefendant.pepBoolean = pepBoolean;
          currentDefendant.publicOfficialBoolean = publicOfficialBoolean;
          update();
      }
  }, [pepBoolean, publicOfficialBoolean]);

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Datos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Check
                  label="Perfil politico prioritario"
                  type="switch"
                  name="pep"
                  id="pep"
                  checked={currentDefendant?.pepBoolean ?? false}
                  value={currentDefendant?.pepBoolean}
                  onChange={(e) => setPepBoolean(e.target.checked)}
                  className={styles.label}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Check
                  label="Funcionario público"
                  type="switch"
                  name="publicOfficial"
                  id="publicOfficial"
                  checked={currentDefendant?.publicOfficialBoolean ?? false}
                  value={currentDefendant?.publicOfficialBoolean}
                  onChange={(e) => {
                    setPublicOfficialBoolean(e.target.checked);
                  }}
                  className={styles.label}
                />
              </Form.Group>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FuncionarioModal;
