import React, { useState, useEffect } from "react";
import { Button, Form, Table, Col, Row } from "react-bootstrap";
import { FiTrash } from "react-icons/fi";
import styles from "../DenunciaForm.module.scss";
import { useKeycloak } from "@react-keycloak/web";
import { BsFilePerson } from "react-icons/bs";
import DenunciantesModal from "../DenunciantesModal/DenunciantesModal";
import axios from "axios";
import toast from "react-hot-toast";
import { IoPersonCircleOutline } from "react-icons/io5";
import AddPersonModal from "../../modals/AddPersonModal";
import FuncionarioModal from "../../modals/FuncionarioModal";
import PersonModal from "../../modals/PersonModal/PersonModal";

const Denunciados = ({ next, previous, defendants, setDefendants }) => {
  const [validated, setValidated] = useState(false);
  // const [publicOfficial, setPublicOfficial] = useState(false);
  // const [publicOfficialTitle, setPublicOfficialTitle] = useState(undefined);
  // const [pep, setPep] = useState(false);
  // const [pepTitle, setPepTitle] = useState("");
  // const [description, setDescription] = useState(undefined);
  const [cartera, setCartera] = useState(undefined);
  const [isDetail, setIsDetail] = useState(false);
  const [currentDefendant, setCurrentDefendant] = useState(undefined);
  const [person, setPerson] = useState({
    documentType: "CI",
    documentNumber: null,
    firstName: "",
    lastName: "",
    birthDate: null,
    gender: "",
    maritalStatus: "",
    profession: "",
    alias: "",
    addresses: [],
    contacts: [],
    personInstitutions: [],
    agents: [],
  });
  const [isCreate, setIsCreate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isModalFuncionario, setIsModalFuncionario] = useState(false);
  const { keycloak } = useKeycloak();

  // const handleChangePep = (value) => {
  //   setPepTitle(value);
  // };

  const handleNext = (e) => {
    e.preventDefault();
    next(defendants);
  };

  const skip = (e) => {
    e.preventDefault();
    next([]);
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    previous(defendants);
  };

  // const handleSubmit = async () => {
  //   await setPerson((prevPerson) => ({
  //     ...prevPerson,
  //     publicOfficial,
  //     pep,
  //     cartera,
  //   }));
  //   if (person.documentNumber !== null && person.documentNumber !== "") {
  //     createPerson();
  //   }
  //   setDefendants([...defendants, person]);
  // };


  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const removeDefendant = (e, defendant) => {
    e.preventDefault();
    let aux = [];
    for (let current of defendants) {
      if (current !== defendant) {
        aux.push(current);
      }
    }
    setDefendants(aux);
  };

  const createPerson = () => {
    const URL = `${process.env.REACT_APP_API_URL}/person`;
    axios
      .post(URL, person, prepareHeaders())
      .then((res) => {
        toast.success("Persona registrada exitosamente", {
          position: "top-center",
        });
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar la persona ", {
          position: "top-center",
        });
        console.log(err);
      });
  };

  const updateDataFuncionario = async () => {
    if (currentDefendant) {
      currentDefendant.cartera = cartera;
      const updatedDefendants = defendants.map((defendant) =>
        defendant.documentNumber === currentDefendant.documentNumber
          ? currentDefendant
          : defendant
      );
      setDefendants(updatedDefendants);
      setIsModalFuncionario(false);
      console.log(defendants);
    }
  };
  return (
    <>
      {/* PersonDetailsModal */}
      <PersonModal
        show={isDetail}
        onHide={() => setIsDetail(false)}
        personId={person.personId}
      />

      {/* IsCreate */}
      <DenunciantesModal
        showModal={isCreate}
        onHide={() => {
          setIsCreate(false);
          setPerson({});
        }}
        setModal={setShowModal}
        setPerson={setPerson}
        person={person}
        person_id={person.id}
      />

      {/* // ModalFuncionario */}
      <FuncionarioModal
        show={isModalFuncionario}
        onHide={() => setIsModalFuncionario(false)}
        setDefendants={setDefendants}
        defendants={defendants}
        currentDefendant={currentDefendant}
      />

      {/* AddDenunciado */}
      <AddPersonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        setIsCreate={setIsCreate}
        title={"Agregar Denunciado"}
        complainants={defendants}
        setComplainants={setDefendants}
      />

      <Form validated={validated}>
        <Row>
          <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
            <Button
              onClick={async () => {
                setShowModal(true);
              }}
              bsPrefix={styles.next}
            >
              Agregar Denunciado
            </Button>
          </Col>
        </Row>

        {defendants && defendants.length > 0 ? (
          <Table className={styles.table} style={{ marginTop: "1rem" }}>
            <thead>
              <th>Nombres y apellidos</th>
              <th>Tipo de documento</th>
              <th>Número de documento</th>
              <th>Fecha de nacimiento</th>
              <th></th>
            </thead>
            <tbody>
              {defendants.map((defendant) => (
                <>
                  <tr>
                    <td>{`${defendant.firstName} ${defendant.lastName}`}</td>
                    <td>
                      {defendant.documentType ? defendant.documentType : "-"}
                    </td>
                    <td>
                      {defendant.documentNumber
                        ? defendant.documentNumber
                        : "-"}
                    </td>
                    <td>{defendant.birthDate}</td>
                    <td>
                      <IoPersonCircleOutline
                        onClick={async (e) => {
                          e.preventDefault();
                          await setPerson(defendant);
                          setIsDetail(true);
                        }}
                        title="Ver"
                        className={styles.icon}
                      />
                      &nbsp;
                      <BsFilePerson
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentDefendant(defendant);
                          setCartera(defendant.cartera);
                          setIsModalFuncionario(true);
                        }}
                        title="Ver"
                        className={styles.icon}
                      />
                      &nbsp;
                      <FiTrash
                        onClick={(e) => removeDefendant(e, defendant)}
                        title="Descartar"
                        className={styles.icon}
                      />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        ) : (
          <></>
        )}

        <Row
          className="mt-4"
          style={{
            display: "flex",
            borderTop: "1px solid silver",
            paddingTop: ".7rem",
          }}
        >
          <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
            <Button bsPrefix={styles.next} onClick={handlePrevious}>
              ANTERIOR
            </Button>
          </Col>
          {defendants.length > 0 ? (
            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
              <Button bsPrefix={styles.next} onClick={handleNext}>
                SIGUIENTE
              </Button>
            </Col>
          ) : (
            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
              <Button bsPrefix={styles.next} onClick={skip}>
                OMITIR
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
};

export default Denunciados;
