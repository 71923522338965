import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./DnitModal.module.scss";
import { use } from "react";

const DnitModal = ({
  show,
  handleClose,
  callback,
  data,
  add,
}) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [dnitReportId, setDnitReportId] = useState("");
  const [reportDnitNumber, setReportDnitNumber] = useState("");
  const [reportDnitDate, setReportDnitDate] = useState("");
  const [pep, setPep] = useState(false);
  const [publicOfficial, setPublicOfficial] = useState(false);
  const [elaborationDate, setElaborationDate] = useState("");
  const [receivedAt, setReceivedAt] = useState("");
  const [fiscalizationType, setFiscalizationType] = useState("");
  const [notificationFiscalizationDate, setNotificationFiscalizationDate] = useState("");
  const [fiscalizationNumber, setFiscalizationNumber] = useState("");
  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  useEffect(() => {
    if (data) {
      setDnitReportId(data.dnit_report_id);
      setReportDnitNumber(data.report_dnit_number);
      setReportDnitDate(data.report_dnit_date);
      setPep(data.pep);
      setPublicOfficial(data.public_official);
      setElaborationDate(data.elaboration_date);
      setReceivedAt(data.received_at);
      setFiscalizationType(data.fiscalization_type);
      setNotificationFiscalizationDate(data.notification_fiscalization_date);
      setFiscalizationNumber(data.fiscalization_number);
    }
  }, [data]);

  const buildBody = () => {
    return {
      dnit_report_id: dnitReportId,
      report_dnit_number: reportDnitNumber,
      report_dnit_date: reportDnitDate,
      pep,
      public_official: publicOfficial,
      elaboration_date: elaborationDate,
      received_at: receivedAt,
      fiscalization_type: fiscalizationType,
      notification_fizcalization_date: notificationFiscalizationDate,
      fiscalization_number: fiscalizationNumber
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/dnit/${data.dnit_report_id}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        callback();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
      });
  };

  return data ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Datos Generales DNIT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mt-2 mb-4">
              <Form.Group
                className="mb-3"
                as={Col}
              >
                <Form.Floating>
                  <Form.Control
                    id="floatingReportDnitNumber"
                    required={!preCarga}
                    type="text"
                    value={reportDnitNumber}
                    className={styles.input}
                    placeholder="Ingrese el número de reporte"
                    onChange={(e) => {
                      //setReportDnitNumber(e.target.value);
                    }}
                  />
                  <Form.Label
                    htmlFor="floatingReportDnitNumber"
                    className={styles.label}
                  >
                    Número de reporte
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingReportDnitDate"
                    required={!preCarga}
                    type="text"
                    value={reportDnitDate}
                    className={styles.input}
                    placeholder="Ingrese la fecha de reporte"
                    onChange={(e) => {
                      //setReportDnitDate(e.target.value);
                    }}
                  />
                  <Form.Label
                    htmlFor="floatingReportDnitDate"
                    className={styles.label}
                  >
                    Fecha del reporte
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingPep"
                      type="text"
                      value={pep ? "Si" : "No"}
                      className={styles.input}
                      onChange={(e) => {
                        //setPep(e.target.value);
                      }}
                    >
                    </Form.Control>
                    <Form.Label
                      htmlFor="floatingPep"
                      className={styles.label}
                    >
                      Persona expuesta politicamente
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingPublicOfficial"
                      required={!preCarga}
                      type="text"
                      value={publicOfficial ? "Si" : "No"}
                      className={styles.input}
                      onChange={(e) => {
                        //setPublicOfficial(e.target.value);
                        }}
                        readOnly
                      />
                      <Form.Label
                        htmlFor="floatingPublicOfficial"
                        className={styles.label}
                      >
                        Funcionario Público
                      </Form.Label>
                      </Form.Floating>
                    </Form.Group>
                    </Row>
                    <Row>
                    <Form.Group as={Col}>
                      <Form.Floating>
                      <Form.Control
                        id="floatingElaborationDate"
                        required={!preCarga}
                        value={elaborationDate}
                        className={styles.input}
                        readOnly
                      />
                      <Form.Label
                        htmlFor="floatingElaborationDate"
                        className={styles.label}
                      >
                        Fecha de elaboración
                      </Form.Label>
                      </Form.Floating>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      as={Col}
                    >
                      <Form.Floating>
                      <Form.Control
                        id="floatingReceivedAt"
                        type="text"
                        value={receivedAt}
                        className={styles.input}
                        readOnly
                        onChange={(e) => {
                        //setReceivedAt(e.target.value);
                      }}
                    >
                    </Form.Control>
                    <Form.Label
                      htmlFor="floatingReceivedAt"
                      className={styles.label}
                    >
                      Recibido
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingFiscalizationType"
                      required={!preCarga}
                      value={fiscalizationType}
                      className={styles.input}
                      onChange={(e) => {
                        //setFiscalizationType(e.target.value);
                      }}
                      readOnly
                    />
                    <Form.Label
                      htmlFor="floatingFiscalizationType"
                      className={styles.label}
                    >
                      Tipo de fiscalización
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  as={Col}
                >
                  <Form.Floating>
                    <Form.Control
                      id="floatingNotificationFiscalizationDate"
                      type="text"
                      value={notificationFiscalizationDate}
                      className={styles.input}
                      onChange={(e) => {
                        //setNotificationFiscalizationDate(e.target.value);
                      }}
                      readOnly
                    >
                    </Form.Control>
                    <Form.Label
                      htmlFor="floatingNotificationFiscalizationDate"
                      className={styles.label}
                    >
                      Fiscalización notificada el
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingFiscalizationNumber"
                      required={!preCarga}
                      value={fiscalizationNumber}
                      className={styles.input}
                      onChange={(e) => {
                        //setFiscalizationNumber(e.target.value);
                      }}
                      readOnly
                    />
                    <Form.Label
                      htmlFor="floatingFiscalizationNumber"
                      className={styles.label}
                    >
                      Número de fiscalización
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};
export default DnitModal;
