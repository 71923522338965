import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

const TrabajosPerson = ({ jobs = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!jobs.length) {
    return <div>No hay datos laborales registrados</div>;
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < jobs.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const currentJob = jobs[currentIndex];

  return (
    <div>
      <h5>Trabajos</h5>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Rol</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentJob.rol || ""}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Descripción del rol</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentJob.rol_description || ""}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Fecha de inicio</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentJob.from_date || ""}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Fecha de término</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={currentJob.to_date || ""}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* If you have additional job fields (like institution name), you can display them similarly:
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3">
            <Form.Label>Institución</Form.Label>
            <Form.Control type="text" readOnly value={currentJob.institution_name || ""} />
          </Form.Group>
        </Col>
      </Row>
      */}

      <p style={{ marginTop: "1rem" }}>
        Mostrando {currentIndex + 1} de {jobs.length}
      </p>
      <div style={{ textAlign: "right" }}>
        <Button
          variant="outline-primary"
          onClick={handlePrev}
          disabled={currentIndex === 0}
          style={{ marginRight: "0.5rem" }}
        >
          Anterior
        </Button>
        <Button
          variant="outline-primary"
          onClick={handleNext}
          disabled={currentIndex === jobs.length - 1}
        >
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default TrabajosPerson;
