import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Row, Col, Button } from "react-bootstrap";
import {
  RiUserLine,
  RiMapPinLine,
  RiPhoneLine,
  RiBuildingLine,
} from "react-icons/ri"; 
import ContactoInstitution from "./ContactosInstitution";
import { useKeycloak } from "@react-keycloak/web";
import DireccionesInstitution from "./DireccionesInstitution";
import DatosGeneralesInstitution from "./DatosGeneralesInstitution";
import { BiSolidInstitution } from "react-icons/bi";

const InstitutionModal = ({ institutionId, show, onHide }) => {
  const [institutionData, setInstitutionData] = useState(null);
  const [activeTab, setActiveTab] = useState("datosGenerales"); 
  const { keycloak } = useKeycloak();
  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };
  useEffect(() => {
    if (institutionId) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/institutions/${institutionId}`, prepareHeaders())
        .then((res) => {
          if (res.status === 200) {
            setInstitutionData(res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching institutions data:", error);
        });
    }
  }, [show]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Row>
          <Col>
            <BiSolidInstitution
              style={{
                cursor: "pointer",
                marginRight: "1rem",
                color: activeTab === "datosGenerales" ? "#0d6efd" : "black",
              }}
              size={24}
              title="Datos Generales"
              onClick={() => handleTabClick("datosGenerales")}
            />
            <RiMapPinLine
              style={{
                cursor: "pointer",
                marginRight: "1rem",
                color: activeTab === "direcciones" ? "#0d6efd" : "black",
              }}
              size={24}
              title="Direcciones"
              onClick={() => handleTabClick("direcciones")}
            />
            <RiPhoneLine
              style={{
                cursor: "pointer",
                marginRight: "1rem",
                color: activeTab === "contactos" ? "#0d6efd" : "black",
              }}
              size={24}
              title="Contactos"
              onClick={() => handleTabClick("contactos")}
            />
            {/* <RiBuildingLine
              style={{
                cursor: "pointer",
                marginRight: "1rem",
                color: activeTab === "trabajos" ? "#0d6efd" : "black",
              }}
              size={24}
              title="Trabajos"
              onClick={() => handleTabClick("trabajos")}
            /> */}
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        {activeTab === "datosGenerales" && (
          <DatosGeneralesInstitution institutionData={institutionData} />
        )}
        {activeTab === "direcciones" && (
          <DireccionesInstitution addresses={institutionData?.address || []} />
        )}
        {activeTab === "contactos" && (
          <ContactoInstitution contacts={institutionData?.contacts || []} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InstitutionModal;
